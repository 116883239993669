import React, { useState, useMemo, useEffect } from "react";
import Table from "../pages/ReactTable"
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData } from "../store/utils";
import Swal from "sweetalert2";
import { FaFilePdf } from 'react-icons/fa';

const FileUpload = () => {
  const [fileData, setFileData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");

  const getFileUploadList = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    setIsLoadingOn(true);

    let apiUrl = `filesUpload?page=${page + 1}&pageSize=${pageSize}`;

    if (sortBy && orderBy) {
      apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    }
    const tempData = await getData(apiUrl);
    console.log("ActualData", tempData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
        setFileData(tempData.data.records);
        setPageCount(tempData.data.pagination.totalPagesCount);
        setTotalRecords(tempData.data.pagination.totalRecords);
        setErrorMessage("");
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  };


  const redirectToFileUploadPage = () => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.location = "/ImportedFile";
    }
  };


  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
        setErrorMessage(`No data to display`);
    } else {
        setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";
  
  if (sortBy === column.id) {
    newOrderBy = orderBy === "asc" ? "desc" : "asc";
  }
  setSortBy(column.id);
  setOrderBy(newOrderBy);
  getFileUploadList(pageIndex, pageSize, column.id, newOrderBy);
  };
  
  useEffect(() => {
    getFileUploadList(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "File Path",
        accessor: "filePath",
        Cell: ({ cell }) => {
            const filePath = cell.row.original.filePath;
            const fileName = filePath.split('/').pop(); 
    
            return (
                <div className="loanIdDownloadBtnDiv">
                    <a 
                        href={filePath} 
                        download={fileName} 
                        style={{ fontWeight: "600", textDecoration: "none", color: "#007bff" }} 
                    >
                        <center><FaFilePdf style={{color:"red",fontSize:25}}/></center>
                    </a>
                </div>
            );
        },
    },
      {
        Header: "Operation",
        accessor: "operation",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Total Records",
        accessor: "totalRecords",
      },
      {
        Header: "Total Success Records",
        accessor: "totalSuccessRecords",
      },
      {
        Header: "Total Failed Records",
        accessor: "totalFailedRecords",
      },
    ],
    [pageIndex,pageSize]
  );

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">File Upload List</h3>
      <button className="searchTableData" style={{float:"right",marginTop:"-35px",marginBottom:"10px",marginLeft:"20px"}}
        onClick={()=>redirectToFileUploadPage()}
      >View</button>
      <div className="orderListDataFooter">
        {fileData && <Table 
        columns={columns} 
        data={fileData} 
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        totalRecords={totalRecords}
        handleGotoPage={handleGotoPage}
        errorMessage={errorMessage} 
        handleSort={handleSort}
        sortBy={sortBy}
        orderBy={orderBy}
        />}
      </div>
    </>
  );
};

export default FileUpload;


