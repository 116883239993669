import React from 'react'
import Routes from './component/Routes';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './css/style.css'
function App() {
  return (
    <div className="App">
      <Routes/>
    </div>
  );
}

export default App;
