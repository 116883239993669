import React, { useState, useMemo, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { FaSearch, FaDownload } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import Table from "../pages/ReactTable"
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData } from "../store/utils";
import { formatDateTime } from "../component/common";

const Attendance = () => {
  const inputRef = useRef();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [records, setRecords] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [queryParams, setQueryParams] = useState("");

  const getLeaveRequestsData = async (
    page = pageIndex,
    pageSize = pageSize,
    sortBy = sortBy,
    orderBy = orderBy,
    queryParams = queryParams
  ) => {
    if (isLoadingOn) return;
    
    setIsLoadingOn(true);
    let apiUrl = `attendance?page=${page + 1}&pageSize=${pageSize}`;
    if (sortBy && orderBy) {
      apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    }
    if (queryParams) {
      apiUrl += `&${queryParams}`;
    }

    try {
      const getDataResult = await getData(apiUrl);
      if (getDataResult.statusCode == 200) {
        setRecords(getDataResult.data.records);
        setPageCount(getDataResult.data.pagination.totalPagesCount);
        setTotalRecords(getDataResult.data.pagination.totalRecords);

        if (getDataResult?.data?.pagination?.totalRecords == 0) {
          setErrorMessage("No data found");
        } else {
          setErrorMessage("");
        }
      }
      else {
        Swal.fire({
          title: "Error!",
          text: getDataResult?.response?.data?.errors?.msg || "Something went wrong."
        })
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An error occurred while fetching data."
      });
    } finally {
      setIsLoadingOn(false);
    }
  };

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    if (column.disableSortBy) return;
    let newOrderBy = "asc";
  
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
  };
  
  useEffect(() => {
    getLeaveRequestsData(pageIndex, pageSize, sortBy, orderBy, queryParams);
  }, [pageIndex, pageSize, sortBy, orderBy, queryParams]);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: "employeeName",
      },
      {
        Header: "Punch-In",
        accessor: "punchInTime",
        Cell: ({ cell }) => (
          <span>{cell?.row?.original?.punchInTime ? formatDateTime(cell.row.original.punchInTime) : ''}</span>
        ),
      },
      {
        Header: "Punch-Out",
        accessor: "punchOutTime",
        Cell: ({ cell }) => (
          <span>{cell?.row?.original?.punchOutTime ? formatDateTime(cell.row.original.punchOutTime) : ''}</span>
        ),
      },
      {
        Header: "Selfie",
        accessor: "filePath",
        Cell: ({ cell }) => {
          const filePath = cell.row.original.filePath;
          const fileName = filePath.split('/').pop(); 
  
          return (
            <div className="loanIdDownloadBtnDiv">
              <a 
                href={filePath} 
                download={fileName} 
                style={{ fontWeight: "600", textDecoration: "none", color: "#007bff" }} 
              >
                <center><FaDownload style={{color:"red",fontSize:25}}/></center>
              </a>
            </div>
          );
        },
      },
    ],
    [pageIndex, pageSize]
  );

  const searchManualData = async () => {
    let filterData = {};

    if (searchBoxValue) {
      filterData = {
        employeeName: searchBoxValue,
      };
    } else {
      alert("Please enter employee name to search.");
      return;
    }

    setPageIndex(0);
    const tempQueryParams = new URLSearchParams(filterData).toString();
    setQueryParams(tempQueryParams);
  };

  const resetFilters = () => {
    inputRef.current.value = "";
    setSearchBoxValue("");
    setQueryParams("");
    setPageIndex(0);
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Attendance</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-4">
              <input
                type="text"
                className="searchInputField w-100"
                ref={inputRef}
                onChange={(e) => setSearchBoxValue(e.target.value)}
                placeholder="Enter employee name"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-3">
              <button
                type="button"
                className="searchTableData mx-2"
                onClick={searchManualData}
              >
                <FaSearch /> Search
              </button>

              <button
                type="button"
                className="resetTableData"
                onClick={resetFilters}
              >
                <HiRefresh /> Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {records && <Table 
          columns={columns} 
          data={records} 
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          totalRecords={totalRecords}
          handleGotoPage={handleGotoPage}
          errorMessage={errorMessage} 
          handleSort={handleSort}
          sortBy={sortBy}
          orderBy={orderBy}
        />}
      </div>
    </>
  );
};

export default Attendance;