import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination,
    useSortBy,
} from "react-table";
import { FaSearch, } from 'react-icons/fa';
import { HiRefresh } from "react-icons/hi";
import '../css/makeTable.css';
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import { getData, postData } from '../store/utils';
import Loader from '../component/Loader';
import Modal from 'react-modal';
import { FaRegTimesCircle } from 'react-icons/fa';
import TopShowMessage from '../component/TopShowMessage';
import Swal from "sweetalert2"
import userEvent from '@testing-library/user-event';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: "none",
        padding: "0px",
    },
};



function LoanAmountStatus() {

    const [merchantUserData, setMerchantUserData] = useState();
    const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
    const [merchantActiveStatus, setMerchantActiveStatus] = useState();
    const [merchantRefrenceType, setMerchantRefrenceType] = useState();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [merchantLoanData, setMerchantLoanData] = useState();
    const [searchBoxValue, setSearchBoxValue] = useState();
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [showTopMessage, setShowTopMessage] = useState(false)
    const [loanStatusData, setLoanStatusData] = useState();
    const [applyFor, setApplyFor] = useState('loan');
    const [selectedOption, setSelectedOption] = useState(null);
    const [optionStatusType, setOptionStatusType] = useState();
    const [selectedStatusOption, setSelectedStatusOption] = useState(null);
    const [paymentData, setPaymentData] = useState({
        paymentAmount: '',
        surchargeAmount: '',
        markDuplicateUTR: '',
        paymentReceiptMode: '',
        paymentReceiptSource: '',
        markSuccessStatement: '',
        applyPayment: '',
        paymentReceiptDate: '',
        utrNumber: '',
        payerVPA: '',
        partnerTxnID: '',
        partner: '',
        operatingBank: '',
        comment: '',
        paymentRequestReason: '',
    });

    const inputRef = useRef();
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        
        const file = event.target.files[0];
        if (file) {
            Swal.fire({
                title: "Are you sure?",
                text: `Do you want to upload the file "${file.name}"?`,
                icon: "warning",
                confirmButtonText: "Send",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                dangerMode: true,
            }).then((willUpload) => {
                if (willUpload) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    
                    reader.onloadend = async (userId) => {
                        const base64String = reader.result.split(',')[1];

                        const bulkUploadResponse = await postData(`repayment/bulk-import`, {
                            createdBy:322,
                            file: base64String
                          })
                          console.log("first",bulkUploadResponse);
                          if (bulkUploadResponse.statusCode == 200) {
                            Swal.fire("Success", "File has been uploaded", "success");
                            window.location.reload(); 
                        } else {
                            Swal.fire("Error", bulkUploadResponse.data.errors.msg, "error");
                        }
                    }
                    reader.onerror = (error) => {
                        console.error("Error converting file to base64:", error);
                        Swal.fire("Error", "Failed to process the file", "error");
                    };
                } else {
                    // Handle the cancellation of the file upload
                    console.log("File upload cancelled");
                    Swal.fire("Cancelled", "File upload has been cancelled", "info");
                }
            });
            
        }
    }

    const resetFilters = () => {
        inputRef.current.value = '';
        setMerchantRefrenceType('');
        setSearchBoxValue('');
        updateOrderStatus();
        setSelectedOptionStatus(null);
        setSelectedOption(null);
    };


    const data = useMemo(
        () => [
            { id: 1, name: 'John Doe', age: 25, city: 'New York', mobileNo: '9988776786' },
        ],
        []
    );

    const searchManualData = async () => {
        let filterData = {};

        if (merchantRefrenceType === "Loan Id") {
            filterData = {
                loanId: searchBoxValue,
            };
        } else if (merchantRefrenceType === "Loan Id" && merchantActiveStatus) {
            filterData = {
                status: merchantActiveStatus,
                loanId: searchBoxValue,
            };     
        }
        else if (merchantRefrenceType === "Order Id") {
            filterData = {
                orderId: searchBoxValue,
            };
        }
        else if (merchantRefrenceType === "Order Id" && merchantActiveStatus) {
            filterData = {
                status:merchantActiveStatus,
                orderId: searchBoxValue,    
            };
        }
        else if (merchantRefrenceType === "Mobile Number") {
            filterData = {
                mobileNumber: searchBoxValue,
            };
        }
        else if(merchantActiveStatus){
            filterData = {
                status:merchantActiveStatus,
            };
        }
        else {
            // alert("Please select any one option");
        }
        console.log("dshfdsjfh Fflter loan", filterData);
        setIsLoadingOn(true);

        const queryParams = new URLSearchParams(filterData).toString();

        console.log("queryParams---", queryParams)
        console.log("filterData---", filterData)

        const tempData = await getData(`order/getAllInvoiceDueDate/1?${queryParams}`);
        // console.log("tempData---", tempData);

        setIsLoadingOn(false);

        if (tempData.statusCode === 200) {
            setMerchantUserData(tempData.data);
        }
        else{
            Swal.fire({
                title:"Error!",
                text:tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    };

    let selectRef = null;
    const history = useHistory();

    const updateOrderStatus = async () => {
        setIsLoadingOn(true);
        const tempData = await getData("order/getAllInvoiceDueDate/1")
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            setMerchantUserData(tempData.data);
        }
        else{
            Swal.fire({
                title:"Error!",
                text:tempData?.data?.errors.msg || "Something went wrong!!!"
            })
        }
    }

    useEffect(() => {
        updateOrderStatus();
    }, [])

    const statusModalOpen = (item) => {
        console.log("gsdkf---", item)
        setLoanStatusData(item);
        setIsOpen(true);
    };

    const paymentOption = [
        { value: 'Loan Id', label: 'Loan Id' },
        { value: 'Loan Amount', label: 'Loan Amount' },
        { value: 'Status', label: 'Status' },
    ]

    const handleSavePayment = async () => {
        let filterData = {
            "userId": parseFloat(loanStatusData.userId),
            "loanId": loanStatusData.loanId,
            "paymentAmount": paymentData.paymentAmount,
            "surchargeAmount": paymentData.surchargeAmount,
            "paymentRequestReason": paymentData.paymentRequestReason,
            "paymentReceiptDate": paymentData.paymentReceiptDate,
            "paymentReceiptMode": paymentData.paymentReceiptMode,
            "paymentReceiptSource": paymentData.paymentReceiptSource,
            "utrNumber": paymentData.utrNumber,
            "payerVPA": paymentData.payerVPA,
            "partnerTxnId": paymentData.partnerTxnID,
            "partner": paymentData.partner,
            "bankId": paymentData.operatingBank,
            "successStatement": paymentData.markSuccessStatement,
            "comment": paymentData.comment,
            "applyPayment": paymentData.applyPayment,
            "markDuplicateUtr": paymentData.markDuplicateUTR
        };
        console.log("filterData------", filterData)
        setIsLoadingOn(true);
        const tempData = await postData("loan/addPayment", filterData)
        console.log("tempData----", tempData)
        setIsLoadingOn(false);
        if (tempData.statusCode == 200) {
            closeModal();
            setIsOpen(false);
            setShowTopMessage(true);
            setTimeout(() => {
                setShowTopMessage(false);
            }, 3000);
        }

    };

    const handleInputChange = (e) => {
        if (merchantRefrenceType === "Order Id" || "Loan Id" || "Loan Amount" || "Merchant Id") {
            const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
            setSearchBoxValue(value);
        } else {
            setSearchBoxValue(e.target.value);
        }
    };

    const handleRadioChange = (event) => {
        setPaymentData({
            ...paymentData,
            [event.target.name]: event.target.value,
        });
    };

    const handleRadioChangeForLoanFilter = (event) => {
        setApplyFor(event.target.value);
    };

    const closeModal = () => {
        setIsOpen(false);
    }
    const redirectToFileUploadPage = () => {
        const newTab = window.open("", "_blank");
        if (newTab) {
          newTab.location = "/FileUpload";
        }
    };

    const searchLoanData = async () => {
        
        if (searchBoxValue && applyFor == "loan") {
            // console.log("applyForloan----",applyFor)
            let tempSearchData = '';
            let temploanId = '';
            for (let i = 0; i < merchantUserData.length; i++) {
                const element = merchantUserData[i];
                if (element.loanId == searchBoxValue) {
                    tempSearchData = element.userId
                    temploanId = element.loanId
                }
            }
            // console.log("element----", tempSearchData)
            let filterData = {
                "userId": tempSearchData,
                "loanId" : temploanId
            };
            setIsLoadingOn(true);
            const tempData = await postData("users/userLoanDetails", filterData)
            setIsLoadingOn(false);
            if (tempData.statusCode == 200) {
                setMerchantLoanData(tempData.data)
            }
        }
        else if (searchBoxValue && applyFor == "user") {
            // console.log("applyForuser----",applyFor)
            let tempSearchData = '';
            for (let i = 0; i < merchantUserData.length; i++) {
                const element = merchantUserData[i];
                if (element.userId == searchBoxValue) {
                    tempSearchData = element.userId
                }
            }
            let filterData = {
                "userId": tempSearchData,
            };
            setIsLoadingOn(true);
            const tempData = await postData("users/userLoanDetails", filterData)
            setIsLoadingOn(false);
            if (tempData.statusCode == 200) {
                setMerchantLoanData(tempData.data)
            }
        }
        else {
            alert("Please enter valid userId/loanId")
        }
    }
    const formatIndianNumber = (value) => {
        return value.toFixed(2).replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    };
    const columns = useMemo(
        () => [
            {
                Header: "Sr. No.",
                accessor: (row, i) => i + 1,
                Cell: ({ cell }) => (
                  <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1}.</span>
                ),
            },
            {
                Header: 'Loan Id',
                accessor: "loanId",
                Cell: ({ cell }) => {
                    return (
                        <>
                            <div className="loanIdDownloadBtnDiv">
                                <button
                                    className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                                    title="View details"
                                    value={cell.row.original.loanId}
                                    // onClick={() =>
                                    //     displayMerchantInfo(cell.row.original)
                                    // }
                                    onClick={() => {
                                        if (localStorage.getItem('USER_ROLE') == 'true') {
                                            statusModalOpen(cell.row.original);
                                        }
                                    }}
                                >
                                    <span style={{ fontWeight: "600" }}>{cell.row.original.loanId}</span>
                                </button>
                            </div>
                        </>
                    );
                },
                filter: "fuzzyText",
                disableSortBy: true,
                disableFilters: false,
            },
            {
                Header: 'Order Id',
                accessor: 'orderId',
                Cell: ({ cell }) => {
                    const orderType = cell.row.original;
                    const handleClick = (user) => {
                        if (orderType.type === "MERCHANT") {
                            window.open(`FundInvoiceDetails?invoiceId=${orderType.orderId}`, '_blank');
                        } else if (orderType.type === "ORDER") {
                            window.open(`OrderDetailsPage?userId=${orderType.userId}&orderId=${orderType.orderId}`, '_blank');
                        }
                    };
                    return (
                        <>
                            <button
                                    className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                                    title="View details"
                                    onClick={handleClick}
                                >
                                    <span style={{ fontWeight: "600" }}>{orderType.orderId}</span>
                                </button>

                        </>
                    );
                },

            }, 
            {
              Header: 'Loan Type',
              accessor: 'type'
          },
           {
              Header: 'Shop Owner Name',
              accessor: 'userName'
          },
            // {
            // Header: 'Shop Name',
            // accessor: 'userName'
            // },
            {
                Header: 'Disbursed Amount',
                accessor: 'totalDisbursedAmount',
                Cell: ({ value }) => {
                    if (typeof value === 'number') {
                        return formatIndianNumber(value);
                    } else {
                        return value; 
                    }
                },

            },
             {
                Header: 'Repayment Amount',
                accessor: 'amountWithExtraCharges',
                Cell: ({ value }) => {
                    if (typeof value === 'number') {
                        return formatIndianNumber(value);
                    } else {
                        return value; // or handle the error case in a way that suits your application
                    }
                },
            },
            {
                Header: 'EMI Date',
                accessor: 'emiDate'
            },
            {
              Header: "Status Name",
              accessor: "statusName",
              Cell: ({ cell }) => {
                const status = cell.row.original.statusName;
                return (
                  <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {status && (
                <span
                  class={`badge badge-pill ${
                    status == "Paid" ? "badge-success" : status == "Paid By Credit" ? 'badge-warning' : 
                    status == "Pending" ? 'badge-danger':"badge-primary"
                  }`}
                >
                  {status}
                </span>
              )}
            </div>  
                  </>
                );
              },
            },
           
            // {
            //     Header: 'Status',
            //     accessor: 'repaymentStatus',
            // },
            {
              Header: "Action",
              accessor: "",
              Cell: ({ cell }) => {
               const userId =  cell.row.original.userId;
               const orderId =  cell.row.original.orderId;
               const loanType =  cell.row.original.type;
                return (
                  <>
                    <div className="loanIdDownloadBtnDiv text-center">
                      <button
                        className={`btn btn-outline-success`}
                        onClick={() => {
                          const userRoleCheck = localStorage.getItem("USER_ROLE");
                          if (
                            userRoleCheck === "Admin" ||
                            userRoleCheck === "Operation"
                          ) {
                            // statusModalOpen(cell.row.original);
                            const newTab = window.open("", "_blank");
                            if (newTab) {
                              newTab.location =
                                "/RepaymentDetails?userId=" + userId + '&orderId=' + orderId + "&loanType=" + loanType;
                            }
                          }
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>View</span>
                      </button>
                    </div>
                  </>
                );
              },
            },
        ],
        
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    );
    const StatusOption = [
        { value: "", label: "All" },
        { value: "15", label: "Pending" },
        { value: "17", label: "Partial Paid" },
        { value: "16", label: "Paid" },
      ];
    const merchantOption = [
        { value: 'Loan Id', label: 'Loan Id' },
        { value: 'Mobile Number', label: 'Mobile Number' },
        { value: 'Order Id', label: 'Order Id' },
    ]

    return (
        <div>
            {isLoadingOn ? <Loader /> : ""}
            {showTopMessage ? <TopShowMessage message="You Have Successfully Update Loan Status" /> : ""}
            <h4 className='tableHeaderMsg'>Loan Payment</h4>
            <button className="searchTableData" style={{float:"right",marginTop:"-35px"}}
                onClick={()=>redirectToFileUploadPage()}
            >History</button>
            <div className='customHeaderTable' style={{marginTop:"30px"}}>
                <div className="container-fluid">
                    <div className="row alignmentTable">
                    <div className="col-md-2">
                        <Select
                            ref={(ref) => {
                            selectRef = ref;
                            }}
                            placeholder="Select Status"
                            name="role"
                            options={StatusOption}
                            value={selectedOptionStatus}
                            onChange={(e) => {
                            setSelectedOptionStatus(e);
                            setMerchantActiveStatus(e.value);
                            }}
                        />
                        </div>
                        <div className="col-md-2">
                            <Select
                                placeholder="Select Search"
                                name="role"
                                options={merchantOption}
                                value={selectedOption}
                                onChange={(e) => {
                                    setMerchantRefrenceType(e.value);
                                    setSelectedOption(e)
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <input
                                type={merchantRefrenceType === "Order Id" || "Loan Id" || "Loan Amount" || "Merchant Id"? 'tel' : 'text'}
                                className='searchInputField'
                                ref={inputRef}
                                // onChange={(e) => {
                                //     setSearchBoxValue(e.target.value);
                                // }}
                                onChange={handleInputChange}
                                placeholder='Enter Details'
                                value={searchBoxValue}
                            />
                        </div>
                        <div className="col-md-2 text-center">
                            <button className='searchTableData'
                                onClick={searchManualData}
                            > Search</button>
                        </div>

                        <div className="col-md-2 text-center" onClick={resetFilters}>
                            <button className='resetTableData' ><HiRefresh /> Reset</button>
                        </div>
                        
                        <div className="col-md-2 text-center">
                        <input
                            type="file"
                            accept='.csv'
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <button onClick={handleButtonClick} className="formbold-browse">
                            Upload File
                        </button>   
                        </div>
                       {/* <div className='col-md-1'>
                            <button className='searchTableData' style={{marginLeft:"-15px"}}
                            onClick={()=>redirectToFileUploadPage()}
                            >History</button>
                       </div> */}
                    </div>
                </div>


            </div>
            <div className='orderListDataFooter'>
                {merchantUserData && <Table columns={columns} data={merchantUserData} />}
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button className='modalCloseBtn' onClick={closeModal}><FaRegTimesCircle /></button>
                <h4 className='pleaseSelectStatus'>Please Select Loan Status</h4>
                <div className='modalUserPaymentStatus'>
                    <div className='applyForLoan'>
                        <p>Apply For</p>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="paymentApplyFor"
                                id="user"
                                value="user"
                                checked={applyFor === 'user'}
                                onChange={handleRadioChangeForLoanFilter}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio1">
                                User
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="paymentApplyFor"
                                id="loan"
                                value="loan"
                                checked={applyFor === 'loan'}
                                onChange={handleRadioChangeForLoanFilter}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio2">
                                Loan
                            </label>
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Details"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            onChange={(e) => setSearchBoxValue(parseFloat(e.target.value) || 0)}
                        />
                        <button
                            className="btn btn-success"
                            type="button"
                            id="button-addon2"
                            onClick={searchLoanData}
                        >
                            Search
                        </button>
                    </div>
                    {merchantLoanData && merchantLoanData.length > 0 ? (
                        <>
                            <table className='customerLoanDetailsTable'>
                                <thead>
                                    <tr>
                                        <th>Customer Name</th>
                                        <th>Mobile No.</th>
                                        <th>Email</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {merchantLoanData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.mobileNumber}</td>
                                            <td>{item.email}</td>
                                            <td>₹ {item.totalAmount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='mb-1 mt-4'>
                                <p>Payment Request Reason</p>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentRequestReason"
                                        id="loanPayment"
                                        value="loanPayment"
                                        checked={paymentData.paymentRequestReason === 'loanPayment'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                        Loan Payment
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentRequestReason"
                                        id="foreclosureFees"
                                        value="foreclosureFees"
                                        checked={paymentData.paymentRequestReason === 'foreclosureFees'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        Foreclosure Fees
                                    </label>
                                </div>
                            </div>
                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Payment Amount (Includes Surcharge Amount)
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="₹ 10000"
                                    onChange={(e) => setPaymentData({ ...paymentData, paymentAmount: parseFloat(e.target.value) || 0 })}
                                />
                            </div>
                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Surcharge Amount
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="₹ 10000"
                                    onChange={(e) => setPaymentData({ ...paymentData, surchargeAmount: parseFloat(e.target.value) || 0 })}
                                />
                            </div>
                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Payment Receipt Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="₹ 10000"
                                    onChange={(e) => setPaymentData({ ...paymentData, paymentReceiptDate: e.target.value })}
                                />
                            </div>

                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Payment Receipt Mode
                                </label>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptMode"
                                        id="inlineRadio1"
                                        value="bankTransfer"
                                        checked={paymentData.paymentReceiptMode === 'bankTransfer'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                        Bank Transfer
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptMode"
                                        id="razorpay"
                                        value="razorpay"
                                        checked={paymentData.paymentReceiptMode === 'razorpay'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        Razorpay
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptMode"
                                        id="cheque"
                                        value="cheque"
                                        checked={paymentData.paymentReceiptMode === 'cheque'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio3">
                                        Cheque
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptMode"
                                        id="cash"
                                        value="cash"
                                        checked={paymentData.paymentReceiptMode === 'cash'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio4">
                                        Cash
                                    </label>
                                </div>
                            </div>
                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Payment Receipt Source
                                </label>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptSource"
                                        id="GpayIntent"
                                        value="GpayIntent"
                                        checked={paymentData.paymentReceiptSource === 'GpayIntent'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                        Gpay Intent
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptSource"
                                        id="phonePeIntent"
                                        value="phonePeIntent"
                                        checked={paymentData.paymentReceiptSource === 'phonePeIntent'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        PhonePe Intent
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptSource"
                                        id="paytmIntent"
                                        value="paytmIntent"
                                        checked={paymentData.paymentReceiptSource === 'paytmIntent'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        Paytm Intent
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptSource"
                                        id="GpayOmni"
                                        value="GpayOmni"
                                        checked={paymentData.paymentReceiptSource === 'GpayOmni'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        Gpay Omni
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptSource"
                                        id="upiCollect"
                                        value="upiCollect"
                                        checked={paymentData.paymentReceiptSource === 'upiCollect'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        UPI Collect
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptSource"
                                        id="VPA"
                                        value="VPA"
                                        checked={paymentData.paymentReceiptSource === 'VPA'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        VPA
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="paymentReceiptSource"
                                        id="directTransfer"
                                        value="directTransfer"
                                        checked={paymentData.paymentReceiptSource === 'directTransfer'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        Direct Transfer
                                    </label>
                                </div>
                            </div>
                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    UTR Number
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="56584625"
                                    onChange={(e) => setPaymentData({ ...paymentData, utrNumber: parseFloat(e.target.value) || 0 })}
                                />
                            </div>
                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Payer VPA
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Details"
                                    onChange={(e) => setPaymentData({ ...paymentData, payerVPA: e.target.value })}
                                />
                            </div>
                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Partner Txn ID
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Txn Id"
                                    onChange={(e) => setPaymentData({ ...paymentData, partnerTxnID: parseFloat(e.target.value) || 0 })}
                                />
                            </div>

                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Partner
                                </label>
                                <Select
                                    placeholder="Select..."
                                    name="role"
                                    options={paymentOption}
                                    onChange={(e) => setPaymentData({ ...paymentData, partner: e.value })}
                                />
                            </div>

                            <div className="mb-1 mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Select Operating Bank
                                </label>
                                <Select
                                    placeholder="Select..."
                                    name="role"
                                    options={paymentOption}
                                    onChange={(e) => setPaymentData({ ...paymentData, operatingBank: e.value })}
                                />
                            </div>

                            <div className='applyForLoan mt-4'>
                                <label htmlFor="exampleFormControlInput1" style={{ marginRight: 20 }} className="form-label">
                                    Mark Success Statement:
                                </label>

                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="markSuccessStatement"
                                        id="inlineRadio1"
                                        value="Yes"
                                        checked={paymentData.markSuccessStatement === 'Yes'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="markSuccessStatement"
                                        id="inlineRadio2"
                                        value="No"
                                        checked={paymentData.markSuccessStatement === 'No'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        No
                                    </label>
                                </div>
                            </div>

                            <div className="mb-1 mt-2">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Comment
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder=""
                                    onChange={(e) => setPaymentData({ ...paymentData, comment: e.target.value })}
                                />
                            </div>
                            <div className='applyForLoan mt-4'>
                                <label htmlFor="applyPayment" style={{ marginRight: 20 }} className="form-label">
                                    Apply Payment:
                                </label>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="applyPaymentYes"
                                        name="applyPayment"
                                        value="Yes"
                                        checked={paymentData.applyPayment === 'Yes'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="applyPaymentYes">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="applyPaymentNo"
                                        name="applyPayment"
                                        value="No"
                                        checked={paymentData.applyPayment === 'No'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="applyPaymentNo">
                                        No
                                    </label>
                                </div>
                            </div>

                            <div className='applyForLoan mt-4'>
                                <label htmlFor="markDuplicateUTR" style={{ marginRight: 20 }} className="form-label">
                                    Mark Duplicate UTR:
                                </label>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="markDuplicateUTRYes"
                                        name="markDuplicateUTR"
                                        value="Yes"
                                        checked={paymentData.markDuplicateUTR === 'Yes'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="markDuplicateUTRYes">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="markDuplicateUTRNo"
                                        name="markDuplicateUTR"
                                        value="No"
                                        checked={paymentData.markDuplicateUTR === 'No'}
                                        onChange={handleRadioChange}
                                    />
                                    <label className="form-check-label" htmlFor="markDuplicateUTRNo">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div style={{ textAlign: "center", marginTop: 20, }}>
                                {/* <button onClick={updateOrderStatus} className='btn btn-success'>Submit</button> */}
                                <button className='btn btn-success' onClick={handleSavePayment}>Save Payment</button>
                            </div>
                        </>
                    ) : null
                    }

                </div>
            </Modal>

            {/* ------------- status View Modal ---------------------- */}

        </div>
    );
}

export default LoanAmountStatus;

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{" "}
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className="searchFilter"
            />
        </span>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <div>
            <input
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={(e) => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <input
                value={filterValue[0] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: "70px",
                    marginRight: "0.5rem",
                }}
            />
            to
            <input
                value={filterValue[1] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: "70px",
                    marginLeft: "0.5rem",
                }}
            />
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        //state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy, //sort data
        usePagination //use pagination
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    //const firstPageRows = rows.slice(0, 10);

    return (
        <>
            <div>

                <table {...getTableProps()} className="dashboardTable">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="tableHeader"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? "↓"
                                                    : "↑"
                                                : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {/* <tr>
                    <th
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                    </th>
                  </tr> */}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className="tableTd">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot className='orderTableFooter'>
                        {footerGroups.map(group => (
                            <tr {...group.getFooterGroupProps()}>
                                {group.headers.map(column => (
                                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </table>
                <div className="row m-0">
                    <div className="pagination col-lg-12">
                        <div className="col-lg-2">
                            <button
                                className="defaultPaginationButton"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {">"}
                            </button>{" "}
                            <button
                                className="defaultPaginationButton"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {">>"}
                            </button>{" "}
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Total Records <strong>{rows.length}</strong>{" "}
                            </span>
                        </div>
                        <div className="col-lg-2">
                            <span>
                                Page{" "}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{" "}
                            </span>
                        </div>
                        <div className="col-lg-3">
                            <span>
                                Go to page{" "}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(page);
                                    }}
                                    className="gotoPage"
                                />
                            </span>{" "}
                        </div>
                        <div className="col-lg-3">
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                                className="selectDisplayNoOfRecords"
                            >
                                {[10, 20, 30, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <br />
            </div>
        </>
    );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
