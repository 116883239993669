import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Sidebar from "./Sidebar";
import Lefbar from "./Lefbar";
import Table from "../pages/Table";
import LoginPage from "../Login/LoginPage";
import MerchantDetails from "../pages/MerchantDetails";
import ShopReward from "../pages/ShopReward";
import MerchantSettlements from "../pages/MerchantSettlements";
import OrderList from "../pages/OrderList";
import SMSQuotation from "../pages/SMSQuotation";
import OrderDetailsPage from "../pages/OrderDetailsPage";
import CreditLimit from "../pages/CreditLimit";
import LoanDetails from "../LMS/LoanDetails";
import LoanRepayment from "../LMS/LoanRepayment";
import RepaymentExport from "../LMS/RepaymentExport";
import FileUpload from "../LMS/FileUpload";
import ImportedFile from "../LMS/ImportedFile";
import Loader from "./Loader";
import LoanRepaymentDetails from "../LMS/LoanRepaymentDetails";
import { postData, postDataWithoutToken } from "../store/utils";
import LoanAmountStatus from "../LMS/LoanAmountStatus";
import ProgramsAddData from "../ProgramFile/ProgramAddData";
import ProgramList from "../ProgramFile/ProgramList";
import DistributorBrandList from "../pages/DistributorBrandList";
import AddNotification from "../Notification/AddNotification";
import DocumentsDetails from "../pages/DocumentsDetails";
import ProductCategory from "../pages/ProductCategory";
import DistributorDetails from "../pages/DistributorDetails";

import EditProgram from "../ProgramFile/EditProgram";
import Financier from "../Financier/Financier";
import LeadDistributorList from "../pages/LeadDistributorList";
import FundInvoiceList from "../FundMyInvoice/FundInvoiceList";
import DisbursementFile from "../pages/DisbursementFile";
import loanTape from "../pages/loanTape";

import AddFinancier from "../Financier/AddFinancier";
import EditFinancier from "../Financier/EditFinancier";
import EmployeeList from "../Employee/EmployeeList";
import ForgetPassword from "../Login/ForgetPassword";
import DistributorList from "../pages/Distributor";
import ElectricianList from "../Electrician/ElectricianList";
import ElectricianDetails from "../Electrician/ElectricianDetails";
import FundInvoiceDetails from "../FundMyInvoice/FundInvoiceDetails";
import RepaymentDetails from "../LMS/RepaymentDetails";
import Attendance from "../HRTools/Attendance";
import LeaveRequests from "../HRTools/LeaveRequests";

function Routes() {
  const [isAuthenticated, setIsAuthenticated] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  useEffect(() => {
    const storedAuthState = localStorage.getItem("isAuthenticated");
    if (storedAuthState) {
      setIsAuthenticated(storedAuthState);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = async (userId, password, history) => {
    let tempDocument = {
      emailId: userId,
      password: password,
      platform: "WEB",
      userType: "EMPLOYEE",
    };

    setIsLoading(true);
    const tempData = await postDataWithoutToken(
      `employee/loginEmployee`,
      tempDocument
    );
    setIsLoading(false);
    if (
      tempData.statusCode === 200 &&
      tempData.accessToken &&
      tempData.accessToken.length > 0
    ) {
      localStorage.setItem("isAuthenticated", tempData.accessToken);
      localStorage.setItem("jwtRefreshToken", tempData.refreshToken);
      const user = tempData.data[0];
      if (user && user.role) {
        setIsAuthenticated(tempData.accessToken);
        localStorage.setItem("USER_ROLE", user.role);
        const isAuthenticatedData = localStorage.getItem("isAuthenticated");
        if (isAuthenticatedData && isAuthenticatedData.length > 0) {
          history.push("/");
        } else {
          alert("Unauthorized User");
          history.push("/login");
        }
      }
    } else {
      setIsAuthenticated("");
      localStorage.setItem("isAuthenticated", "");
      localStorage.setItem("jwtRefreshToken", "");
      alert("Invalid username or password");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  const handleLogout = () => {
    setIsAuthenticated("");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("jwtRefreshToken");
    localStorage.removeItem("USER_ROLE");
  };

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <Loader />
        ) : isAuthenticated && isAuthenticated.length > 0 ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <LoginPage handleLogin={handleLogin} />
        )
      }
    />
  );

  return (
    <Router>
      <div className="AppContainer">
        {isAuthenticated &&
          isAuthenticated !== undefined &&
          isAuthenticated.length > 0 && (
            <div
              className="asideMenuBarTab"
              style={{ width: isSidebarCollapsed ? "5%" : "17%" }}
            >
              <Lefbar
                handleLogout={handleLogout}
                toggleSidebar={toggleSidebar}
                isSidebarCollapsed={isSidebarCollapsed}
              />
            </div>
          )}

        <main className="main-content">
          <Switch>
            <Route path="/login">
              {isAuthenticated &&
              isAuthenticated !== undefined &&
              isAuthenticated.length > 0 ? (
                <Redirect to="/" />
              ) : (
                <LoginPage handleLogin={handleLogin} />
              )}
            </Route>
            <Route path="/ForgetPassword">
              <ForgetPassword />
            </Route>
            <PrivateRoute path="/" exact component={Table} />
            <PrivateRoute path="/merchantDetails" component={MerchantDetails} />
            <PrivateRoute path="/shopRewards" component={ShopReward} />
            <PrivateRoute
              path="/MerchantSettlements"
              component={MerchantSettlements}
            />
            <PrivateRoute path="/Distributor" component={DistributorList} />
            <PrivateRoute path="/LoanDetails" component={LoanDetails} />
            <PrivateRoute path="/LoanRepayment" component={LoanRepayment} />
            <PrivateRoute path="/RepaymentExport" component={RepaymentExport} />
            <PrivateRoute path="/FileUpload" component={FileUpload} />
            <PrivateRoute path="/ImportedFile" component={ImportedFile} />
            <PrivateRoute path="/OrderList" component={OrderList} />
            <PrivateRoute path="/SMSQuotation" component={SMSQuotation} />
            <PrivateRoute
              path="/OrderDetailsPage"
              component={OrderDetailsPage}
            />
            <PrivateRoute path="/CreditLimit" component={CreditLimit} />
            <PrivateRoute
              path="/LoanRepaymentDetails"
              component={LoanRepaymentDetails}
            />
            <PrivateRoute
              path="/LoanAmountStatus"
              component={LoanAmountStatus}
            />
            <PrivateRoute path="/ProgramAddData" component={ProgramsAddData} />
            <PrivateRoute path="/EditProgram" component={EditProgram} />
            <PrivateRoute path="/ProgramList" component={ProgramList} />
            <PrivateRoute
              path="/DistributorBrandList"
              component={DistributorBrandList}
            />
            <PrivateRoute path="/AddNotification" component={AddNotification} />
            <PrivateRoute
              path="/DocumentsDetails"
              component={DocumentsDetails}
            />
            <PrivateRoute path="/ProductCategory" component={ProductCategory} />
            <PrivateRoute
              path="/DistributorDetails"
              component={DistributorDetails}
            />
            <PrivateRoute path="/Financier" component={Financier} />
            <PrivateRoute
              path="/LeadDistributorList"
              component={LeadDistributorList}
            />
            <PrivateRoute path="/InvoiceList" component={FundInvoiceList} />
            <PrivateRoute
              path="/FundInvoiceDetails"
              component={FundInvoiceDetails}
            />
            <PrivateRoute
              path="/DisbursementFile"
              component={DisbursementFile}
            />
            <PrivateRoute path="/LoanTape" component={loanTape} />

            <PrivateRoute path="/AddFinancier" component={AddFinancier} />
            <PrivateRoute path="/EditFinancier" component={EditFinancier} />
            <PrivateRoute path="/EmployeeList" component={EmployeeList} />
            <PrivateRoute path="/Electrician" component={ElectricianList} />
            <PrivateRoute path="/ElectricianDetails" component={ElectricianDetails} />
            <PrivateRoute path="/RepaymentDetails" component={RepaymentDetails} />
            <PrivateRoute path="/hr-tools/attendance" component={Attendance} />
            <PrivateRoute path="/hr-tools/leave-requests" component={LeaveRequests} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default Routes;
