import React, { useState, useMemo, useEffect } from "react";
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData } from "../store/utils";
import Swal from "sweetalert2";
import { format } from 'date-fns';
import Table from "../pages/ReactTable"

const ImportedFile = () => {
  const userRole = localStorage.getItem("USER_ROLE");
  const [fileData, setFileData] = useState([]);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");

  const getImportedList = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {
    setIsLoadingOn(true);

    let apiUrl = `repayment/imported?page=${page + 1}&pageSize=${pageSize}`;
    
    if (sortBy && orderBy) {
      apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    }
    const tempData = await getData(apiUrl);
    console.log("ActualData", tempData);
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      setFileData(tempData.data.records);
      setPageCount(tempData.data.pagination.totalPagesCount);
      setTotalRecords(tempData.data.pagination.totalRecords);
      setErrorMessage("");
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors?.msg || "Something went wrong!!!"
      });
    }
  };
    const handleGotoPage = (page) => {
        if (page < 0 || page >= pageCount) {
          setErrorMessage(`No data to display`);

      } else {
        setErrorMessage(""); 
        setPageIndex(page);
      }
    };
  
  const handleSort = (column) => {
    let newOrderBy = "asc";
  
  if (sortBy === column.id) {
    newOrderBy = orderBy === "asc" ? "desc" : "asc";
  }
  setSortBy(column.id);
  setOrderBy(newOrderBy);
  // getImportedList(pageIndex, pageSize, column.id, newOrderBy);
  };

  useEffect(() => {
    getImportedList(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const columns = useMemo(
        () => [
          {
            Header: "Sr. No.",
            accessor: (row, i) => i + 1 + pageIndex * pageSize, // Adjust the index based on current page
            Cell: ({ cell }) => (
                <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>
                    {cell.row.index + 1 + pageIndex * pageSize}.
                </span>
            ),
          },
          {
            Header: "File Upload ID",
            accessor: "filesUploadId",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Failed Reason",
            accessor: "failedReason",
          },
         
          {
            Header: "User ID",
            accessor: "userId",
          },
          {
            Header: "Order ID",
            accessor: "orderId",
          },
          {
            Header: "Received Amount",
            accessor: "receivedAmount",
          },
          {
            Header: "UTR",
            accessor: "utr",
          },
          {
            Header: "Payment Mode",
            accessor: "paymentMode",
          },
          {
            Header: "Payment Platform",
            accessor: "paymentPlatform",
          },
          {
            Header: "Payment Date",
            accessor: "paymentDate",
            Cell: ({ value }) => {
                if (!value || isNaN(new Date(value).getTime())) {
                    return "N/A";
                }
                return format(new Date(value), "dd MMM yyyy");
            },
        },
        {
            Header: "Comment",
            accessor: "comment",
          },
        ],
        [pageIndex,pageSize]
      );

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Imported File List</h3>
      <div className="orderListDataFooter">
        {fileData && (
          <Table
            columns={columns}
            data={fileData}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            handleGotoPage={handleGotoPage}
            errorMessage={errorMessage} 
            handleSort={handleSort}
            sortBy={sortBy}
            orderBy={orderBy}
          />
        )}
      </div>
    </>
  );
};

export default ImportedFile;
