import React, { useMemo, useState, useEffect } from "react";
import "../../css/makeTable.css";
import Loader from "../../component/Loader";

const MerchantRepaymentDetails = () => {
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalImageURl, setModalImageURl] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get("userId");

  // ------------- Merchant Bank Data --------------------------

  // async function merchantGetBankData() {
  //   setIsLoadingOn(true);
  //   const tempData = await getData(`users/get_user_bank/${userId}`);
  //   setIsLoadingOn(false);
  //   if (tempData.statusCode == 200) {
  //     setMerchantBankData(tempData.data[0]);
  //   }
  // }

  // useEffect(() => {
  //   merchantGetBankData();
  // }, []);

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              Invoice 1
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
          >
            <div className="accordion-body">
              <div className="bankDetailsMerchant">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="merchantNameDetails">
                        <h5>-</h5>
                        <p>Date of invoice</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="merchantNameDetails">
                        <h5>-</h5>
                        <p>Schedule Repayment Date</p>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="merchantNameDetails">
                        <h5>-</h5>
                        <p>Repayment Amount</p>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="merchantNameDetails">
                        <h5>-</h5>
                        <p>PF</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="merchantNameDetails">
                        <sapn>
                          -
                        </sapn>
                        <p>Paid Status</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderTopWidth: 1,
                      borderColor: "#ccc",
                      borderStyle: "dashed",
                      marginBottom: 15,
                    }}
                  />
                  {/* -------------------------------------------------------------------------------------- */}
                  <div className="row">
                    <div className="col-md-2">
                      <div className="merchantNameDetails">
                        <h5>-</h5>
                        <p>Repayment Ref No.</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="merchantNameDetails">
                        <h5>-</h5>
                        <p>Actual Payment Date</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="merchantNameDetails">
                        <h5>-</h5>
                        <p>Distributor Name</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantRepaymentDetails;
