import React, { useMemo, useState, useEffect } from "react";
import "../../css/makeTable.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from "../../store/utils";
import Loader from "../../component/Loader";
import Moment from "react-moment";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import { formatDateTime } from "../../component/common";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

const MerchantInnerDetails = () => {
  const [merchantPersonalData, setMerchantPersonalData] = useState([]);
  const [merchantBankData, setMerchantBankData] = useState();
  const [merchantAddressData, setMerchantAddressData] = useState();
  const [merchantDocumentData, setMerchantDocumentData] = useState();
  const [merchantPhotoData, setMerchantPhotoData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalImageURl, setModalImageURl] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  // console.log("merchantPhotoData+++++",merchantPersonalData)

  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get("userId");

  async function merchantGetData(userId) {
    if (userId) {
      setIsLoadingOn(true);
      const tempData = await getData(`users/getMerchantDetails/${userId}`);
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setMerchantPersonalData(tempData.data);
      }
      else{
        Swal.fire({
          title:"Error!",
          text:tempData.data.errors.msg || "Something went wrong",
        })
    }
    }
  }

  useEffect(() => {
    if (userId) {
      merchantGetData(userId);
    }
  }, [userId]);

  // ------------- Merchant Bank Data --------------------------

  async function merchantGetBankData() {
    setIsLoadingOn(true);
    const tempData = await getData(`users/get_user_bank/${userId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantBankData(tempData.data[0]);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData.data.errors.msg || "Something went wrong",
      })
    }
  }

  useEffect(() => {
    merchantGetBankData();
  }, []);

  // ------------- Merchant Bank Data --------------------------

  // ------------- Merchant Shop Address Data --------------------------

  async function merchantGetAddressData() {
    setIsLoadingOn(true);
    const tempData = await getData(`users/getUserShop/${userId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantAddressData(tempData.data[0]);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong",
      })
  }
  }

  useEffect(() => {
    merchantGetAddressData();
  }, []);

  // ------------- Merchant Shop Address Data --------------------------

  // ------------- Merchant Document Data --------------------------

  async function merchantGetDocumentData() {
    setIsLoadingOn(true);
    const tempData = await postData("documents/getDocuments", { userId });
    setIsLoadingOn(false);
    let merchantDocumentPhoto = [];
    let merchantShopPhoto = [];
    if (tempData.statusCode == 200) {
      for (let i = 0; i < tempData.data.length; i++) {
        const element = tempData.data[i];
        if (element.tableReference == "USER_SHOP") {
          merchantShopPhoto.push(element);
        } else if (element.tableReference !== "ORDER") {
          merchantDocumentPhoto.push(element);
        }
      }
      setMerchantDocumentData(merchantDocumentPhoto);
      setMerchantPhotoData(merchantShopPhoto);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong",
      })
    }
  }

  useEffect(() => {
    merchantGetDocumentData();
  }, []);

  // ------------- Merchant Document Data --------------------------

  const imageViewModal = (filePath) => {
    setModalImageURl(filePath);
    setIsOpen(true);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
      {
        id: 2,
        name: "Jane Smith",
        age: 30,
        city: "London",
        mobileNo: "9988776786",
      },
      {
        id: 3,
        name: "Bob Johnson",
        age: 35,
        city: "Paris",
        mobileNo: "9988776786",
      },
      // Add more data as needed
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: "userShopId",
        Cell: ({ cell }) => {
          return (
            <>
              <span style={{ fontWeight: "600" }}>{cell.row.index + 1}</span>
            </>
          );
        },
      },
      {
        Header: "Document Category",
        accessor: "tableReference",
      },
      {
        Header: "Document Type",
        accessor: "documentName",
      },
      {
        Header: "Image",
        accessor: "filePath",
        Cell: ({ cell }) => {
          const fileType = cell.row.original.fileType;
          return (
            <>
              {fileType == "image" ? (
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => imageViewModal(cell.row.original)}
                >
                  <img
                    style={{ maxHeight: 50 }}
                    src={cell.row.original.filePath}
                  />
                </div>
              ) : (
                <a href={cell.row.original.filePath} download>
                  <img style={{ maxHeight: 50 }} src="../assets/img/pdf.png" />
                </a>
              )}
            </>
          );
        },
      },
      {
        Header: "Document Face",
        accessor: "mobileNo",
      },

      {
        Header: "Uploaded Date",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          // console.log("cellimage---",cell)
          return <>{cell.row.original.createdAt}</>;
        },
      },

      {
        Header: "Approval Status",
        accessor: "statusName",
        Cell: ({ cell }) => {
          const verified = cell.row.original.statusName;
          return (
            <>
              {verified == "Pending" ? (
                <button className="btn btn-sm btn-danger">Pending</button>
              ) : verified == "Process" ? (
                <button className="btn btn-sm btn-success">Approved</button>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "Previous Status",
        accessor: "previousStatusName",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              Merchant Personal Details
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
          >
            {merchantPersonalData &&
              merchantPersonalData.map((item, index) => (
                <div key={index} className="accordion-body">
                  <div className="merchantdesignlay">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="merchantNameDetails">
                            <h5>{item.userName}</h5>
                            <p>Name</p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="merchantNameDetails">
                            <h5>
                              {item.mobileNumber
                                ? "XXXXXX" + item.mobileNumber?.slice(-4)
                                : "-"}
                            </h5>
                            <p>Mobile/Alternate Mobile</p>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="merchantNameDetails">
                            <h5>{item.shopName}</h5>
                            <p>Company Name</p>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="merchantNameDetails">
                            {item && (
                              <h5
                                className={`${
                                  item.userStatusName == "Active"
                                    ? "activeStatus"
                                    : item.userStatusName == "Suspended"
                                    ? "SuspendedStatus"
                                    : "defaluStatus"
                                }`}
                              >
                              {item.userStatusName}</h5>
                            )}
                            <p>Status</p> 
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="merchantNameDetails">
                            <h5>{item && formatDateTime(item.createdAt)}</h5>
                            <p>Created At</p>
                          </div>
                        </div>
                      </div>

                      <hr />

                      {/* -------------------------------------------------------------------------------------- */}
                      <div className="row">
                        <div className="col-md-2">
                          <div className="merchantNameDetails">
                            <h5>{item.userReferenceNumber}</h5>
                            <p>User Reference Number</p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="merchantNameDetails">
                            <h5>{item.merchantReferenceNumber}</h5>
                            <p>Merchant Reference Number</p>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="merchantNameDetails">
                            <h5>
                              {item && item.panNumber
                                ? "XXXXXX" + item.panNumber?.slice(-4)
                                : "-"}
                            </h5>
                            <p>PAN Number</p>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="merchantNameDetails">
                            <h5>
                              {item && item.gstNumber
                                ? "XXXXXX" + item.gstNumber?.slice(-4)
                                : "-"}
                            </h5>
                            <p>GST Number</p>
                          </div>
                        </div> 

                        <div className="col-md-3">
                          <div className="merchantNameDetails">
                            <h5>
                              {item && item.onboardingStatusName
                                ? item.onboardingStatusName
                                : "-"}
                            </h5>
                            <p>Onboarding Status</p>
                          </div>
                        </div>
                        {/* <div className="col-md-2">
                          <div className="merchantNameDetails">
                            <h5>{merchantPersonalData.userType}</h5>
                            <p>Shop Type</p>
                          </div>
                        </div> */}

                        {/* <div className="col-md-2">
                          <div className="merchantNameDetails">
                            <h5>{item.fosName}</h5>
                            <p>Assigned FOS Name</p>
                          </div>
                        </div> */}

                        {/* <div className="col-md-3">
                          <div className="merchantNameDetails">
                            <h5>-</h5>
                            <p>Reporting Manager</p>
                          </div>
                        </div> */}
                      </div>
                      <hr />
                      {/* -------------------------------------------------------------------------------------- */}
                      <div className="row">
                        {/* <div className="col-md-2">
                          <div className="merchantNameDetails">
                            <h5>Na/Na</h5>
                            <p>Merchant Type/Source</p>
                          </div>
                        </div> */}
                        
                        
                        
                      </div>
                      {/* -------------------- */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              Merchant Business Details
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseTwo"
            className="accordion-collapse collapse"
          >
            {merchantPersonalData && (
              <div className="accordion-body">
                <div className="businessMerchantDetails">
                  <table>
                    <thead>
                      <tr>
                        <th>Business Category</th>
                        <th>Business Sub Category</th>
                        <th>Onboarding Type</th>
                        <th>Business Status</th>
                        <th>Business Status Updated At</th>
                        <th>Latest User FA Date</th>
                        <th>Su In Last 7 Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {merchantPersonalData
                            ? merchantPersonalData.shopCategoryName
                            : ""}
                        </td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <th>Merchant Type</th>
                        <th>Distributor Reference Number</th>
                        <th>Distributor Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>----</td>
                        <td>----</td>
                        <td>----</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div> */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree"
            >
              Bank Details Confirmed by the Merchant while Onboarding
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseThree"
            className="accordion-collapse collapse"
          >
            {merchantBankData && (
              <div className="accordion-body">
                <div className="bankDetailsMerchant">
                  <div className="container-fluid">
                    {/* -------------------------------------------------------------------------------------- */}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="bankTableDetailsData">
                          <table>
                            <tr>
                              <th>User Bank Reference Number</th>
                              <td>-</td>
                            </tr>

                            <tr>
                              <th>Bank Name</th>
                              <td>
                                {merchantBankData ? merchantBankData.name : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Bank MICR Code</th>
                              <td>-</td>
                            </tr>

                            <tr>
                              <th>Bank Account Holder Name</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.accountHolderName
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Is Verified</th>
                              {/* <td><button className='btn btn-success'>Yes</button></td> */}
                              <td>-</td>
                            </tr>
                            <tr>
                              <th>Verified Name</th>
                              <td>-</td>
                            </tr>

                            <tr>
                              <th>Verification Type</th>
                              <td>-</td>
                            </tr>
                            <tr>
                              <th>Verified Name Match</th>
                              <td>-</td>
                            </tr>
                            <tr>
                              <th>Created At</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.createdAt
                                  : "-"}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="bankTableDetailsData">
                          <table>
                            <tr>
                              <th>Bank Account Number</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.accountNumber
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Bank Branch</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.branchName
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Bank Branch IFSC</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.ifscCode
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Bank Account Type</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.accountType
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Verification Attempts</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.verificationAttempts
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Verified Datetime</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.verifiedDatetime
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Is Active</th>
                              <td>
                                {merchantBankData && merchantBankData.active ? (
                                  <button className="btn btn-success">
                                    Active
                                  </button>
                                ) : (
                                  <button className="btn btn-danger">No</button>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Is Digi Verified</th>
                              <td>
                                <button className="btn btn-danger">No</button>
                              </td>
                            </tr>
                            <tr>
                              <th>Updated At</th>
                              <td>
                                {merchantBankData
                                  ? merchantBankData.updatedAt
                                  : "-"}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFour"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFour"
            >
              Present address of the Shop
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFour"
            className="accordion-collapse collapse"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="bankTableDetailsData">
                    <table>
                      <tr>
                        <th>Address Reference Number</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>Line 1</th>
                        <td>
                          {merchantAddressData
                            ? merchantAddressData.address1
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Land Mark</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>City</th>
                        <td>
                          {merchantAddressData ? merchantAddressData.city : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Tag</th>
                        <td>-</td>
                      </tr>
                      <tr>
                        <th>Residence Type</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>Is Verified</th>
                        {/* <td><button className='btn btn-danger'>No</button></td> */}
                        <td>-</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="bankTableDetailsData">
                    <table>
                      <tr>
                        <th>Room Number</th>
                        <td>
                          {merchantAddressData
                            ? merchantAddressData.shopNumberAndBuildingName
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Line 2</th>
                        <td>
                          {merchantAddressData
                            ? merchantAddressData.address2
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Pincode</th>
                        <td>
                          {merchantAddressData
                            ? merchantAddressData.shopPinCode
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>State</th>
                        <td>
                          {merchantAddressData ? merchantAddressData.state : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Address Verification Status</th>
                        <td>-</td>
                      </tr>
                      <tr>
                        <th>Is Active</th>
                        {/* <td><button className='btn btn-success'>Active</button></td> */}
                        <td>-</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFive"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFive"
            >
              Merchant Document Details
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFive"
            className="accordion-collapse collapse"
          >
            <div className="accordion-body">
              {merchantDocumentData && (
                <Table columns={columns} data={merchantDocumentData} />
              )}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseSix"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseSix"
            >
              Shop Photo and Video
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseSix"
            className="accordion-collapse collapse"
          >
            <div className="accordion-body">
              {merchantPhotoData && (
                <Table columns={columns} data={merchantPhotoData} />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ------------- image View Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {modalImageURl && modalImageURl.fileType == "image" && (
          <div className="modalUserImage">
            <img
              src={modalImageURl && modalImageURl.filePath}
              alt="modalImage"
            />
          </div>
        )}
      </Modal>

      {/* ------------- image View Modal ---------------------- */}
    </>
  );
};

export default MerchantInnerDetails;
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                        <th
                          colSpan={visibleColumns.length}
                          style={{
                            textAlign: 'left',
                          }}
                        >
                        </th>
                      </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
                      {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                          {group.headers.map(column => (
                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                          ))}
                        </tr>
                      ))}
                    </tfoot> */}
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
