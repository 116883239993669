import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch, FaFileUpload } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import { FaCalendarAlt } from "react-icons/fa";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

function CreditLimit() {
  const [userCreditData, setUserCreditData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenMobileNumber, setModalIsOpenMobileNumber] =
    React.useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [selectedOption, setSelectedOption] = useState(" ");
  const [selectedOptionStatus, setSelectedOptionStatus] = useState();
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [lenderNameInfo, setLenderNameInfo] = useState([]);
  const [merchantCreditLimitStatus, setMerchantCreditLimitStatus] = useState(null);
  const [file, setFile] = useState(null)

  const userRole = localStorage.getItem("USER_ROLE");
  
  const [formData, setFormData] = useState({
    creditLimitId: "",
    creditLimit: "",
    tenure: "",
    transactionFees: "",
    processingFees: "",
    userId: "",
    financierId: "",
  });

  const [addCreditLimitData, setAddCreditLimitData] = useState({
    // creditLimitId: -1,
    creditLimit: "",
    tenure: "",
    transactionFees: "",
    processingFees: "",
    mobileNumber: "",
  });

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Access the form data here and perform any necessary actions
    console.log("formdata----", formData);
    setIsLoadingOn(true);
    const res = await postData(`users/updateCreditLimit`, formData);
    setIsLoadingOn(false);
    if (res.statusCode == 200) {
      setIsOpen(false);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Updated successfully!",
      });
    }
    else{
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: res?.data?.errors.msg || 'Somthing went wrong',
      });
    }
  };

  const UploadCreditLimit = async (event) => {
    event.preventDefault();
    if (file) {
      const bulkUploadResponse = await postData(`users/insertCreditLimitByCSV`, {
        file: file
      })
      console.log("Check file ----", file);
      console.log("bulkUploadResponse", bulkUploadResponse);
      if (bulkUploadResponse.statusCode == 200) {

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Uploaded successfully!",
        });
      }
      else {
        console.log('No file selected.');
      }
    }
  }
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type !== 'text/csv') {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Please Select CSV file",
      });
      e.target.value = ''; // Clear the input
      return;
    }
    if (selectedFile) {
      const reader = new FileReader();
      // reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        setFile(base64String);
      };
      reader.readAsDataURL(selectedFile)
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  };

  const handleInputChange = (e) => {
    if (merchantRefrenceType === 'Mobile Number' ||  "Credit Limit" ) {
        const value = e.target.value.replace(/[^0-9.]/g, '').slice(0, 10);
        setSearchBoxValue(value);
    } else {
        setSearchBoxValue(e.target.value);
    }
};

  const handelUpdateSubmit = async (event) => {
    if(!addCreditLimitData.creditLimit){
      Swal.fire({
        icon: "error",
        text: "Please enter credit limit",
      });
    }
    if(!addCreditLimitData.mobileNumber){
      Swal.fire({
        icon: "error",
        text: "Please enter Mobile Number",
      });
    }
    if(!addCreditLimitData.tenure){
      Swal.fire({
        icon: "error",
        text: "Please enter tenure",
      });
    }
    if(!addCreditLimitData.transactionFees){
      Swal.fire({
        icon: "error",
        text: "Please enter Transaction fees",
      });
    }
    if(!addCreditLimitData.processingFees){
      Swal.fire({
        icon: "error",
        text: "Please enter processing Fees",
      });
    }
    event.preventDefault();
    let filteredData = {
      mobileNumber: addCreditLimitData.mobileNumber,
      creditLimit: addCreditLimitData.creditLimit,
      tenure: addCreditLimitData.tenure,
      transactionFees: parseFloat(addCreditLimitData.transactionFees),
      processingFees: parseFloat(addCreditLimitData.processingFees),
    }
    console.log("Filteresd Credit Limit", filteredData);
    const res = await postData(`users/creditLimit`, filteredData);
    console.log(res);
    if (res.statusCode == 200) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data Updated successfully!",
      });
    }
    if (res.statusCode === 401) {
      Swal.fire({
        icon: "error",
        title: "User Not Exist",
        text: "User Not Exists",
      });

    }
    else{
      Swal.fire({
        title:"Error!",
        text:res?.data?.errors.msg || "Something went wrong",
      })
    }

    // setIsOpen(false);
  };

  // Function to handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setAddCreditLimitData({
      ...addCreditLimitData,
      [name]: value,
    });
  };
  const inputRef = useRef();

  const closeModal = () => {
    setIsOpen(false);
    setModalIsOpenMobileNumber(false);
  };
  const handelAddData = async (item) => {
    let tempData = {
      creditLimitId: item.id,
      creditLimit: item.creditLimit,
      tenure: item.tenure,
      transactionFees: item.transactionFees,
      processingFees: item.processingFees,
      userId: item.userId,
    };
    // console.log(tempData);
    setFormData(tempData);
    setIsOpen(true);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
      {
        id: 2,
        name: "Jane Smith",
        age: 30,
        city: "London",
        mobileNo: "9988776786",
      },
      {
        id: 3,
        name: "Bob Johnson",
        age: 35,
        city: "Paris",
        mobileNo: "9988776786",
      },
      // Add more data as needed
    ],
    []
  );
  const history = useHistory();


  const getLimitData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("users/getCreditLimitList/1");
    console.log("tempData---", tempData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setUserCreditData(tempData.data);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong",
      })
    }
  }; 

   const getFinancierLender = async () => {
    let tempLenderData = [];
    setIsLoadingOn(true);
    const tempData = await getData("financier/getFinancierList");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      for (let index = 0; index < tempData.data.length; index++) {
        const element = tempData.data[index];
        if(element){
          tempLenderData.push({
            label:element.nameNBFC, value:element.id
          })
        }
      }
      setLenderNameInfo(tempLenderData);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong",
      })
    }
  };

  useEffect(() => {
    getLimitData();
    getFinancierLender();
  }, []);

  const handelAddCreditLitmi = () => {
    setModalIsOpenMobileNumber(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1}.</span>
        ),
      },
      {
        Header: "User Ref. No.",
        accessor: "referenceNo",
        Cell: ({ cell }) => {
          return (
            <>
              <button
                title="View Details"
                className="viewButtonMean"
                onClick={() => {
                  // console.log("shfdbsdf",userRole);
                  if (userRole === "Admin" || userRole === "Operation" || userRole === "Tendative") {
                    handelAddData(cell.row.original);
                  }
                }}
              >
                {cell.row.original.userReferenceNumber}
              </button>
            </>
          );
        },
      },
      {
        Header: "Credit Limit",
        accessor: "creditLimit",
      },
      {
        Header: "Used Credit Limit",
        accessor: "usedCreditLimit",
      },
      {
        Header: "Avail Credit Limit",
        accessor: "availCreditLimit",
      },
      {
        Header: "Credit Limit Status",
        accessor: "crditLimitStatusName",
        Cell: ({ cell }) => {
          const verified = cell.row.original.crditLimitStatusName;
          return (
            <>
              {verified == "Processing" ? (
                <button className="btn btn-sm btn-warning">Processing</button>
              ) : verified == "Approved" ? (
                <button className="btn btn-sm btn-success">Approved</button>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "Tenure",
        accessor: "tenure",
      },
      {
        Header: "Transaction Fees",
        accessor: "transactionFees",
      },
      {
        Header: "Processing Fees",
        accessor: "processingFees",
      },
      {
        Header: "Name",
        accessor: "firstName",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const resetFilters = () => {
    inputRef.current.value = "";
    setSelectedOption(null);
    setSelectedOptionStatus(null);
    setSearchBoxValue("");
    getLimitData();
  };

  const merchantOption = [
    // { value: "Reference No.", label: "Reference No." },
    { value: "Credit Limit", label: "Credit Limit" },
    { value: "Mobile Number", label: "Mobile Number" },
  ];
  const statusOption = [
    { value: " ", label: "All" },
    { value: "4", label: "Processing" },
    { value: "5", label: "Approved" },
  ];

  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "Reference No.") {
      filterData = {
        status: merchantCreditLimitStatus,
        userReferenceNumber: searchBoxValue,
      };
    }
    if (merchantRefrenceType === "Mobile Number") {
      filterData = {
        status: merchantCreditLimitStatus,
        mobileNumber: searchBoxValue,
      };
    }
    else if (merchantRefrenceType === "Credit Limit") {
      filterData = {
        status: merchantCreditLimitStatus,
        creditLimit: searchBoxValue,
      };
    } else if (merchantRefrenceType === "Name") {
      filterData = {
        status: merchantCreditLimitStatus,
        name: searchBoxValue,
      };
    } else if (merchantCreditLimitStatus) {
      filterData = {
        status: merchantCreditLimitStatus,
      };
    } else {
      alert("Please select any one option");
    }


    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    console.log("queryParams---", queryParams);
    console.log("filterData---", filterData);

    const tempData = await getData(`users/getCreditLimitList/1?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setUserCreditData(tempData.data);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong",
      })
    }
  };

  return (
    <>
      <div>
        {isLoadingOn ? <Loader /> : ""}
        <div className="row alignmentTable">
          <div className="col-md-9">
            <h3 className="tableHeaderMsg">Credit Limit</h3>
          </div>
          <div className="col-md-2" style={{marginTop:"-20px",marginBottom:"10px"}}>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              id="fileInput"
              className="form-control"
            />
          </div>
          <div className="col-md-1" style={{marginTop:"-20px",marginBottom:"10px"}}>
          <button style={{backgroundColor:"#f7674c",Color:"white"}}
              onClick={UploadCreditLimit}
              className="btn"
            >
              Bulk <FaFileUpload />
            </button>
          </div>
        </div>
        <div className="customHeaderTable">
          <div className="container-fluid">
            <div className="row alignmentTable">
              <div className="col-md-2">
                <Select
                  placeholder="Select Status"
                  name="role"
                  options={statusOption}
                  value={selectedOptionStatus}
                  onChange={(selected) => {
                    setMerchantCreditLimitStatus(selected.value);
                    setSelectedOptionStatus(selected);
                  }}
                />
              </div>
              <div className="col-md-2">
                <Select
                  placeholder="Select ..."
                  name="role"
                  options={merchantOption}
                  value={selectedOption}
                  onChange={(selected) => {
                    setMerchantRefrenceType(selected.value);
                    setSelectedOption(selected);
                  }}
                />
              </div>
              <div className="col-md-2">
                <input
                  type={merchantRefrenceType === "Mobile Number" || "Credit Limit" ? 'tel' : 'text'}
                  className="searchInputField"
                  ref={inputRef}
                  // onChange={(e) => {
                  //   setSearchBoxValue(e.target.value);
                  // }}
                  onChange={handleInputChange}
                  placeholder="Enter Details"
                  value={searchBoxValue}
                />
              </div>
              <div className="col-md-2 text-center" onClick={searchManualData}>
                <button className="searchTableData">
                  <FaSearch /> Search
                </button>
              </div>

              <div className="col-md-2 text-center" onClick={resetFilters}>
                <button className="resetTableData">
                  <HiRefresh /> Reset
                </button>
              </div>
              {userRole === "Admin" || userRole === "Operation" || userRole === "Tendative" ? (
                <div className="col-lg-2 text-end">
                  <button
                    className="btn btnAddCredit"
                    onClick={() => {
                      console.log("dfkjbf",userRole);
                      handelAddCreditLitmi();
                    }}
                  >
                    Add
                  </button>
                </div>
              ): null}

              {/* <div className="col-md-1" style={{marginTop:"-7px", paddingLeft:"30px"}}> 
              </div> */}
            </div>
          </div>
        </div>

        {userCreditData && <Table columns={columns} data={userCreditData} />}
      </div>
      {/* ------------- add credit Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <div className="modalUserImage">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Credit Limit
              </label>
              <input
                type="text"
                placeholder="Enter Credit Limit"
                className="form-control"
                id="creditLimit"
                name="creditLimit"
                value={formData.creditLimit}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
                  <label htmlFor="financierId" className="form-label">
                    Lender Name
                  </label>
                  <Select
                    placeholder="Select"
                    name="financierId"
                    required
                    options={lenderNameInfo}
                    value={lenderNameInfo.find(
                      (option) => option.value === formData.financierId
                    )}
                    onChange={(selectedOption) =>
                      setFormData({ ...formData, financierId: selectedOption.value })
                    }
                  />
                </div>

            {/* <div className="mb-3">
              <label htmlFor="tenure" className="form-label">
                Tenure
              </label>
              <input
                type="text"
                placeholder="Enter Tenure"
                className="form-control"
                id="tenure"
                name="tenure"
                value={formData.tenure}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div> */}
            {/* <div className="mb-3">
              <label htmlFor="transactionFees" className="form-label">
                Transaction Fees
              </label>
              <input
                type="number"
                placeholder="Enter Transaction Fees"
                className="form-control"
                id="transactionFees"
                name="transactionFees"
                value={formData.transactionFees}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div> */}
            {/* <div className="mb-3">
              <label htmlFor="processingFee" className="form-label">
                Processing fee
              </label>
              <input
                type="number"
                placeholder="Enter Processing fee"
                className="form-control"
                id="processingFees"
                name="processingFees"
                value={formData.processingFees}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
            </div> */}
            <div className="text-center">
              <button
                type="submit"
                // onClick={handelSubmit}
                className="btn btn-success text-center"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* ------------- add credit Modal ---------------------- */}

      {/* ------------- add credit Mobile Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpenMobileNumber}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <div className="modalUserImage">
          <form onSubmit={handelUpdateSubmit}>
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Credit Limit
              </label>
              <input
                type="text"
                placeholder="Enter Credit Limit"
                className="form-control"
                id="creditLimit"
                name="creditLimit"
                value={addCreditLimitData.creditLimit}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="tenure" className="form-label">
                Tenure
              </label>
              <input
                type="text"
                placeholder="Enter Tenure"
                className="form-control"
                id="tenure"
                name="tenure"
                value={addCreditLimitData.tenure}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="transactionFees" className="form-label">
                Transaction Fees
              </label>
              <input
                type="number"
                placeholder="Enter Transaction Fees"
                className="form-control"
                id="transactionFees"
                name="transactionFees"
                value={addCreditLimitData.transactionFees}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="processingFee" className="form-label">
                Processing fee
              </label>
              <input
                type="number"
                placeholder="Enter Processing fee"
                className="form-control"
                id="processingFees"
                name="processingFees"
                value={addCreditLimitData.processingFees}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div> */}
            <div className="mb-3">
              <label htmlFor="mobileNo" className="form-label">
                Mobile Number
              </label>
              <input
                type="number"
                placeholder="Enter Mobile Number"
                className="form-control"
                id="mobileNo"
                name="mobileNumber"
                value={addCreditLimitData.mobileNumber}
                onChange={handleUpdateChange}
                aria-describedby="textHelp"
              />
            </div>
            <div className="mb-3">
                  <label htmlFor="financierId" className="form-label">
                    Lender Name
                  </label>
                  <Select
                    placeholder="Select"
                    name="financierId"
                    required
                    options={lenderNameInfo}
                    value={lenderNameInfo.find(
                      (option) => option.value === formData.financierId
                    )}
                    onChange={(selectedOption) =>
                      setFormData({ ...formData, financierId: selectedOption.value })
                    }
                  />
                </div>
            <div className="text-center">
              <button
                type="submit"
                onClick={() => handelUpdateSubmit}
                className="btn btn-success text-center"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* ------------- add credit Mobile Modal ---------------------- */}
    </>
  );
}

export default CreditLimit;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                    <th
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                    </th>
                  </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
                  {footerGroups.map(group => (
                    <tr {...group.getFooterGroupProps()}>
                      {group.headers.map(column => (
                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                      ))}
                    </tr>
                  ))}
                </tfoot> */}
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
