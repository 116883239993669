import React, { useState, useEffect } from "react";
import { getData, postData } from "../store/utils";
import Swal from "sweetalert2";
import Loader from "../component/Loader";
import { useHistory, useLocation } from "react-router-dom";

const EditFinancier = () => {
  const history = useHistory();
  const [submittedData, setSubmittedData] = useState([]); 
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoadingOn, setIsLoadingOn] = useState(false);

  const [formData, setFormData] = useState({
    nameNBFC: "",
    addressNBFC: "",
    LogoNBFC: "",
    grivenceName: "",
    grivenceEmail: "",
    grivenceMobileno: "",
    grivenceAddress: "",
    fileName: "", // Add this line
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const financierId = queryParams.get("id");
  console.log("FormData", formData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64URL = reader.result;
        const logo = base64URL.split(",")[1];
        setFormData({
          ...formData,
          LogoNBFC: logo,
          // fileName: file.name,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // alert("Ismail");
    const filteredData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== null)
    );
    console.log("Form Data", filteredData);
    filteredData.financierId = financierId;
    if (validateForm()) {
      setIsLoadingOn(true);
      const res = await postData(`users/updateFinancierDeatails`, filteredData);
      console.log("Test sdmhfvsdf sdhbfdshfv", res);
      setIsLoadingOn(false);
      if (res.statusCode == 200) {
        history.push("/Financier");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Data Updated successfully!",
        });
      }
      setErrors({});
    }
  };

  const GetDataOnEdit = async (financierId) => {
    try {
      const financierData = await getData(
        `users/getFinancierDeatails/nothing?financierId=${financierId}`
      );
      if (financierData.statusCode == 200) {
        console.log("ArrayData2024", financierData.data);
      }
      const data = financierData.data[0];
      setFormData(data);
      // if (data.LogoNBFC) {
      //   const base64Logo = await getBase64FromUrl(data.LogoNBFC);
      //   setFormData({
      //     ...data,
      //     LogoNBFC: base64Logo,
      //   });
      // } else {
      //   setFormData(data);
      // }
    } catch (error) {
      console.error("Error loading program data:", error);
    }
  };
  useEffect(() => {
    if (financierId) {
      setIsEditMode(true);
      GetDataOnEdit(financierId);
    }
  }, []);
  const validateForm = () => {
    const errors = {};

    if (!formData.nameNBFC) {
      errors.nameNBFC = "NBFC Name is required";
    }
    if (!formData.addressNBFC) {
      errors.addressNBFC = "NBFC Address is required ";
    }
    if (!formData.LogoNBFC) {
      errors.LogoNBFC = "Brand logo is required ";
    }
    if (!formData.grivenceName) {
      errors.grivenceName = "Grievance Officer Name is required";
    }
    if (!formData.grivenceEmail) {
      errors.grivenceEmail = "Grievance Officer Email is required";
    }
    if (!formData.grivenceMobileno) {
      errors.grivenceMobileno = "Grievance Officer Contact is required";
    }
    if (!formData.grivenceAddress) {
      errors.grivenceAddress = "Grievance Officer Address is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  return (
    <div className="container">
      <h3 className="programHead">{isEditMode ? "Update Financier" : ""}</h3>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <h4 className="programDetailsDekho">NBFC Details</h4>
          <hr />

          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                NBFC Name<span>*</span>
              </label>
              <input
                type="text"
                placeholder="Enter NBFC Name"
                className="form-control"
                id="name"
                name="nameNBFC"
                value={formData.nameNBFC}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
              {errors.nameNBFC && (
                <div className="text-danger">{errors.nameNBFC}</div>
              )}
            </div>
          </div>

          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Brand Logo<span>*</span>
              </label>
              <input
                type="file"
                accept="image"
                placeholder="Enter"
                className="form-control"
                name="LogoNBFC"
                onChange={handleLogoChange}
                aria-describedby="textHelp"
              />
              {formData.LogoNBFC && (
                <>
                  <img
                    src={formData.LogoNBFC}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Address <span>*</span>
              </label>
              <textarea
                placeholder="Enter Address"
                className="form-control"
                rows={2}
                cols={20}
                name="addressNBFC"
                value={formData.addressNBFC}
                onChange={handleChange}
                style={{ resize: "none" }}
              />
              {errors.addressNBFC && (
                <div className="text-danger">{errors.addressNBFC}</div>
              )}
            </div>
          </div>
        </div>
        <br />

        {/* Grievance Details */}
        <div className="row">
          <h4 className="programDetailsDekho">
            Grievance Rederssal Person Details
          </h4>
          <hr />

          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Name<span>*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Officer Name"
                className="form-control"
                id="name"
                name="grivenceName"
                value={formData.grivenceName}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
              {errors.grivenceName && (
                <div className="text-danger">{errors.grivenceName}</div>
              )}
            </div>
          </div>

          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Email <span>*</span>
              </label>
              <input
                type="email"
                placeholder="Enter Email Address"
                className="form-control"
                id="name"
                name="grivenceEmail"
                value={formData.grivenceEmail}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
              {errors.grivenceEmail && (
                <div className="text-danger">{errors.grivenceEmail}</div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Phone/Mobile Number<span>*</span>
              </label>
              <input
                type="number"
                placeholder="Enter Phone/Mobile Number"
                className="form-control"
                id="name"
                name="grivenceMobileno"
                value={formData.grivenceMobileno}
                onChange={handleChange}
                aria-describedby="textHelp"
              />
              {errors.grivenceMobileno && (
                <div className="text-danger">{errors.grivenceMobileno}</div>
              )}
            </div>
          </div>

          <div className="col-md-6 changeProductPro">
            <div className="mb-3">
              <label htmlFor="creditLimit" className="form-label">
                Address<span>*</span>
              </label>
              <textarea
                placeholder="Enter Address"
                className="form-control"
                rows={2}
                cols={20}
                name="grivenceAddress"
                value={formData.grivenceAddress}
                onChange={handleChange}
                style={{ resize: "none" }}
              />
              {errors.grivenceAddress && (
                <div className="text-danger">{errors.grivenceAddress}</div>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="text-center">
          <button type="submit" className="btn btn-success text-center">
            {isEditMode ? "Update" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditFinancier;
