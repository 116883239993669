import React, { useMemo, useState, useEffect } from 'react';
import '../css/makeTable.css'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from '../store/utils';
import Loader from '../component/Loader';
import Moment from 'react-moment';
import Modal from 'react-modal';
import { FaRegTimesCircle } from 'react-icons/fa';
import { formatDateTime } from '../component/common';
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: "none"
  },
};

function ElectricianDetails(props) {


  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get("userId");

  const [merchantPersonalData, setMerchantPersonalData] = useState();
  const [merchantBankData, setMerchantBankData] = useState();
  const [merchantAddressData, setMerchantAddressData] = useState();
  const [merchantDocumentData, setMerchantDocumentData] = useState();
  const [merchantPhotoData, setMerchantPhotoData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalImageURl, setModalImageURl] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  console.log("merchantDocumentData123----",merchantPersonalData && merchantPersonalData.userStatusName)

  const closeModal = () => {
    setIsOpen(false);
  }

  async function merchantGetData() {
    if (userId) {
      setIsLoadingOn(true);
      const tempData = await getData(`users/getMerchantDetails/${userId}`)
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setMerchantPersonalData(tempData.data[0]);
      }
      else{
        Swal.fire({
          title:"Error!",
          text:tempData.data.errors.msg || "Something went wrong",
        })
      }
    }
  }

  useEffect(() => {
    merchantGetData();
  }, [userId])


  // ------------- Merchant Document Data --------------------------

  async function merchantGetDocumentData() {
    setIsLoadingOn(true);
    const tempData = await postData('documents/getDocuments', { userId })
    setIsLoadingOn(false);
    let merchantDocumentPhoto = [];
    let merchantShopPhoto = [];
    if (tempData.statusCode == 200) {
      for (let i = 0; i < tempData.data.length; i++) {
        const element = tempData.data[i];
        if (element.tableReference == "USER_SHOP") {
          merchantShopPhoto.push(element);
        }
        else if (element.tableReference !== "ORDER") {
          merchantDocumentPhoto.push(element);
        }
      }
      setMerchantDocumentData(merchantDocumentPhoto);
      setMerchantPhotoData(merchantShopPhoto);
    }
  }

  useEffect(() => {
    merchantGetDocumentData();
  }, [])

  // ------------- Merchant Document Data --------------------------


  const imageViewModal = (filePath) => {
    setModalImageURl(filePath);
    setIsOpen(true);

  }

  const data = useMemo(
    () => [
      { id: 1, name: 'John Doe', age: 25, city: 'New York', mobileNo: '9988776786' },
      { id: 2, name: 'Jane Smith', age: 30, city: 'London', mobileNo: '9988776786' },
      { id: 3, name: 'Bob Johnson', age: 35, city: 'Paris', mobileNo: '9988776786' },
      // Add more data as needed
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Sr. No.',
        accessor: "userShopId",
        Cell: ({ cell }) => {
          return (
            <>
              <span style={{ fontWeight: "600" }}>{cell.row.index + 1}</span>
            </>
          );
        },
      },
      {
        Header: 'Document Category',
        accessor: 'tableReference'
      },
      {
        Header: 'Document Type',
        accessor: 'documentName'
      },
      {
        Header: 'Image',
        accessor: "filePath",
        Cell: ({ cell }) => {
          const fileType = cell.row.original.fileType;
          return (
            <>
              {fileType == "image" ?
               ( <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => imageViewModal(cell.row.original)}>
                  <img style={{ maxHeight: 50, }} src={cell.row.original.filePath} />
                </div>
                ) :
                (
                  <a href={cell.row.original.filePath} download>
                    <img style={{ maxHeight: 50, }} src="../assets/img/pdf.png" />
                  </a>
                )
                
                }
            </>
          );
        },
      },
      {
        Header: 'Document Face',
        accessor: 'mobileNo'
      },

      {
        Header: 'Uploaded Date',
        accessor: 'createdAt',
        Cell: ({ cell }) => {
          // console.log("cellimage---",cell)
          return (
            <>
              {cell.row.original.createdAt}
            </>
          );
        },
      },

      // {
      //   Header: 'Approval Status',
      //   accessor: 'statusName',
      //   Cell: ({ cell }) => {
      //     const verified = cell.row.original.statusName;
      //     return (
      //       <>
      //         {
      //           verified == "Pending" ?
      //             <button className='btn btn-sm btn-danger'>Pending</button>
      //             : verified == "Process" ?
      //               <button className='btn btn-sm btn-success'>Approved</button>
      //               : ''
      //         }
      //       </>
      //     );
      //   },
      // },
      // {
      //   Header: 'Previous Status',
      //   accessor: 'previousStatusName'
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      <div className='merchantDetailsHeading'>
        <h4>Electrician Details</h4>
      </div>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
            Electrician Personal Details
            </button>
          </h2>
          <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
            <div className="accordion-body">
              <div className="merchantdesignlay">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-2">
                      <div className='merchantNameDetails'>
                        <h5>{merchantPersonalData ? merchantPersonalData.name : ""}</h5>
                        <p>Name</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className='merchantNameDetails'>
                        <h5>{merchantPersonalData && merchantPersonalData.mobileNumber ? "XXXXXX" + merchantPersonalData.mobileNumber?.slice(-4) : "-"}</h5>
                        <p>Mobile/Alternate Mobile</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className='merchantNameDetails'>
                        <h5>{merchantPersonalData && merchantPersonalData.email ? merchantPersonalData.email : "-"}</h5>
                        <p>Email Id</p>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className='merchantNameDetails'>
                        <h5>{merchantPersonalData ? merchantPersonalData.createdAt &&  formatDateTime(merchantPersonalData.createdAt) : ''}</h5>
                        <p>Created At</p>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className='merchantNameDetails'>
                        {merchantPersonalData &&
                          <button
                            className={`btn ${merchantPersonalData.userStatusName == "Active" ? "btn-success" : merchantPersonalData.userStatusName == "Suspended" ? 'btn-warning' : 'btn-danger'}`}
                          >
                            {merchantPersonalData.userStatusName}
                          </button>
                        }
                        <p>Status</p>
                      </div>
                    </div>
                  </div>

                  <hr />

                
                  <div className="row">
                    <div className="col-md-5">
                      <div className='merchantNameDetails'>
                        <h5>{merchantPersonalData ? `${merchantPersonalData.currentResidenceAddress ? merchantPersonalData.currentResidenceAddress : "" } ${merchantPersonalData.currentResidenceAddress2 ? merchantPersonalData.currentResidenceAddress2 : ""}` : ""}</h5>
                        <p>Address</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className='merchantNameDetails'>
                        <h5>{merchantPersonalData ? merchantPersonalData.city : ""}</h5>
                        <p>City</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className='merchantNameDetails'>
                        <h5>{merchantPersonalData ? merchantPersonalData.state : ""}</h5>
                        <p>State</p>
                      </div>
                    </div>

                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        
     
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
              Electrician Document Details
            </button>
          </h2>
          <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse">
            <div className="accordion-body">
              {merchantDocumentData && <Table columns={columns} data={merchantDocumentData} />}
            </div>
          </div>
        </div>
      </div>
      {/* ------------- image View Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className='modalCloseBtn' onClick={closeModal}><FaRegTimesCircle /></button>
        {modalImageURl && modalImageURl.fileType == "image" &&
          <div className='modalUserImage'>
            <img src={modalImageURl && modalImageURl.filePath} alt='modalImage' />
          </div>
        }

      </Modal>

      {/* ------------- image View Modal ---------------------- */}
    </div>
  )
}




export default ElectricianDetails;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>

        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? "↓"
                          : "↑"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                      <th
                        colSpan={visibleColumns.length}
                        style={{
                          textAlign: 'left',
                        }}
                      >
                      </th>
                    </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
                    {footerGroups.map(group => (
                      <tr {...group.getFooterGroupProps()}>
                        {group.headers.map(column => (
                          <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                        ))}
                      </tr>
                    ))}
                  </tfoot> */}
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
