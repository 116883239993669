import React from 'react';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
} from "react-table";


function ReactTable({  
    columns, 
    data,
    pageCount,
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    totalRecords,
    handleGotoPage,
    errorMessage, 
    handleSort,
    sortBy,
    orderBy
   }) {
      const filterTypes = React.useMemo(
          () => ({
              text: (rows, id, filterValue) => {
                  return rows.filter((row) => {
                      const rowValue = row.values[id];
                      return rowValue !== undefined
                          ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                          : true;
                  });
              },
          }),
          []
      );
  
      const defaultColumn = React.useMemo(
          () => ({
              // Let's set up our default Filter UI
            //   Filter: DefaultColumnFilter,
          }),
          []
      );
  
      const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          footerGroups,
          rows,
          prepareRow,
          visibleColumns,
          preGlobalFilteredRows,
          setGlobalFilter,
          page,
          canPreviousPage,
          canNextPage,
          pageOptions,
          gotoPage,
          nextPage,
          previousPage,
          state: { pageIndex: tablePageIndex, pageSize: tablePageSize },
      } = useTable(
          {
              columns,
              data,
              defaultColumn, 
              filterTypes,
              manualPagination: true,
              pageCount, 
              initialState: { pageIndex, pageSize},
          },
          useFilters, // useFilters!
          useGlobalFilter, // useGlobalFilter!
          useSortBy, //sort data
          usePagination //use pagination
      );
      const handleNextPage = () => {
          if (canNextPage) {
              setPageIndex(pageIndex + 1);
          }
      };
      const handlePreviousPage = () => {
          if (canPreviousPage) {
              setPageIndex(pageIndex - 1);
          }
      };
      const handleSetPageSize = (size) => {
          setPageSize(size);
          setPageIndex(0);
      };
  
      return (
          <>
              <div>
  
                  <table {...getTableProps()} className="dashboardTable">
                      <thead>
                          {headerGroups.map((headerGroup) => (
                              <tr
                                  {...headerGroup.getHeaderGroupProps()}
                                  className="tableHeader"
                              >
                                  {headerGroup.headers.map((column) => (
                                      <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                      onClick={() => handleSort(column)}
                                      >
                                          {column.render("Header")}
                                          <span>
                                          {column.id === sortBy && (
                                              orderBy === "asc" ? "↑" : "↓"
                                          )}
                                          </span>
                                      </th>
                                  ))}
                              </tr>
                          ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                      {errorMessage ? (
                          <tr style={{
                              height:150
                          }}>
                              <td colSpan={columns.length} style={{ textAlign: 'center', color: 'salmon' }}>
                              <h4><strong>{errorMessage}</strong></h4>
                              </td>
                          </tr>
                          ) : (
                          page.map((row) => {
                              prepareRow(row);
                              return (
                              <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => {
                                  return (
                                      <td {...cell.getCellProps()} className="tableTd">
                                      {cell.render("Cell")}
                                      </td>
                                  );
                                  })}
                              </tr>
                              );
                          })
                          )}
                      </tbody>
                      <tfoot className='orderTableFooter'>
                          {footerGroups.map(group => (
                              <tr {...group.getFooterGroupProps()}>
                                  {group.headers.map(column => (
                                      <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                  ))}
                              </tr>
                          ))}
                      </tfoot>
                  </table>
                  <div className="row m-0">
                      <div className="pagination col-lg-12">
                          <div className="col-lg-2">
                              <button
                                  className="defaultPaginationButton"
                                  onClick={() => handleGotoPage(0)}
                                  disabled={tablePageIndex === 0}
                              >
                                  {"<<"}
                              </button>{" "}
                              <button
                                  className="defaultPaginationButton"
                                  onClick={handlePreviousPage}
                                  disabled={!canPreviousPage}
                              >
                                  {"<"}
                              </button>{" "}
                              <button
                                  className="defaultPaginationButton"
                                  onClick={handleNextPage}
                                  disabled={!canNextPage}
                              >
                                  {">"}
                              </button>{" "}
                              <button
                                  className="defaultPaginationButton"
                                  onClick={() => handleGotoPage(pageCount - 1)}
                                  disabled={!canNextPage}
                              >
                                  {">>"}
                              </button>{" "}
                          </div>
                          <div className="col-lg-2">
                              <span>
                                  Total Records <strong>{totalRecords}</strong>{" "}
                              </span>
                          </div>
                          <div className="col-lg-2">
                              <span>
                                  Page{" "}
                                  <strong>
                                  {tablePageIndex + 1} of {pageCount}
                                  </strong>{" "}
                              </span>
                          </div>
                          <div className="col-lg-3">
                              <span>
                                  Go to page{" "}
                                  <input
                                      type="number"
                                      defaultValue={tablePageIndex + 1}
                                      onChange={(e) => {
                                          const page = e.target.value
                                              ? Number(e.target.value) - 1
                                              : 0;
                                              handleGotoPage(page);
                                      }}
                                      className="gotoPage"
                                  />
                              </span>{" "}
                          </div>
                          <div className="col-lg-3">
                              <select
                                  value={pageSize}
                                  onChange={(e) => {
                                      handleSetPageSize(Number(e.target.value))
                                  }}
                                  className="selectDisplayNoOfRecords"
                              >
                                  {[10, 20, 30, 50].map((pageSize) => (
                                      <option key={pageSize} value={pageSize}>
                                          Show {pageSize}
                                      </option>
                                  ))}
                              </select>
                          </div>
                      </div>
                  </div>
  
                  <br />
              </div>
          </>
      );
  }

  export default ReactTable;