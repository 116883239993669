import React, { useEffect, useState } from 'react';
import '../css/makeTable.css'
import { getData } from '../store/utils';
import Loader from '../component/Loader';
import { formatDateTime } from '../component/common';

function LoanRepaymentDetails(props) {

    const loanId = props && props.location.state && props.location.state.loanId;
    console.log("loanId----", loanId)


    const [userLoanDataDetails, setUserLoanDataDetails] = useState([]);
    const [isLoadingOn, setIsLoadingOn] = useState(false);

    // console.log("userLoanDataDetails----", userLoanDataDetails)

    const getDataLoanRepaymentDetails = async () => {
        setIsLoadingOn(true);
        if (loanId) {
            const tempData = await getData(`loan/getRepaymentAmount/${loanId}`);
            setIsLoadingOn(false);
            if (tempData.statusCode == 200) {
                setUserLoanDataDetails(tempData.data);
            }
        }

    };
    useEffect(() => {
        getDataLoanRepaymentDetails();
    }, [loanId])

    return (
        <div>
            {isLoadingOn ? <Loader /> : ""}
            <div className='merchantDetailsHeading'>
                <h4 style={{ fontWeight: "700" }}>Loan Repayment Details</h4>
            </div>
            <div id="exTab3" className="container">
                <ul className="nav nav-pills">
                    <li className="active">
                        <a href="#1b" data-toggle="tab">
                            Loan Repayment Details
                        </a>
                    </li>
                    <li>
                        <a href="#2b" data-toggle="tab">
                            Active Lender
                        </a>
                    </li>
                    <li>
                        <a href="#3b" data-toggle="tab">
                            Passive Lender
                        </a>
                    </li>
                </ul>
                <div className="tab-content clearfix">
                    <div className="tab-pane active" id="1b">
                        {userLoanDataDetails && userLoanDataDetails.map((item, index) => (
                            <div key={index} className="merchantdesignlay">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                                <h5 style={{marginTop:30, fontSize:17}}>Month {index+1}</h5>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className='merchantNameDetails'>
                                                <h5>{item.emiDate ? formatDateTime(item.emiDate) :"-"}</h5>
                                                <p>Scheduled Payment Date</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                                <h5>₹ {item.amount ? item.amount : "-"}</h5>
                                                <p>Scheduled Payment Amt</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                                <h5>₹ {item.amount ? item.principal : "-"}</h5>
                                                <p>Scheduled Principal Amt</p>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className='merchantNameDetails'>
                                                <h5>₹ {item.amount ? item.interest : "-"}</h5>
                                                <p>Scheduled Interest Amt</p>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    {/* -------------------------------------------------------------------------------------- */}
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                            <h5>-</h5>
                                                <p>Repayment Reference No.</p>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className='merchantNameDetails'>
                                            <h5>-</h5>
                                                <p>Payment Date</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                            <h5>₹ {item.amount ? item.amount : "-"}</h5>
                                                <p>Payment Amount/Pending Amount</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                            <h5>{item.amount ? item.principal : "-"}</h5>
                                                <p>Applied towards principal</p>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className='merchantNameDetails'>
                                                <h5>{item.amount ? item.interest : "-"}</h5>
                                                <p>Applied towards interest</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    {/* -------------------------------------------------------------------------------------- */}
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                                <h5>Na/Na</h5>
                                                <p>Interest Waived</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                            <h5>{item.amount ? item.status : "-"}</h5>
                                                <p>Payment Status</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className='merchantNameDetails'>
                                                <button className='btn btn-outline-warning'>View Mapping</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tab-pane" id="2b">
                        <div className="merchantdesignlay">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>Month 1</h5>
                                            <p></p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='merchantNameDetails'>
                                            <h5>1st October 2023</h5>
                                            <p>Scheduled Payment Date</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>26,717.00</h5>
                                            <p>Scheduled Payment Amt</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>5,805.00</h5>
                                            <p>Scheduled Principal Amt</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='merchantNameDetails'>
                                            <h5>912.00</h5>
                                            <p>Scheduled Interest Amt</p>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                {/* -------------------------------------------------------------------------------------- */}
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>RRPT961364964216YO4C</h5>
                                            <p>Repayment Reference No.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='merchantNameDetails'>
                                            <h5>1st October 2023</h5>
                                            <p>Payment Date</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>6,717.00/20.00</h5>
                                            <p>Payment Amount/Pending Amount</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>5,805.00</h5>
                                            <p>Applied towards principal</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='merchantNameDetails'>
                                            <h5>-</h5>
                                            <p>Applied towards interest</p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {/* -------------------------------------------------------------------------------------- */}
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>Na/Na</h5>
                                            <p>Interest Waived</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Payment Status</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <button>View Mapping</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="3b">
                        <div className="merchantdesignlay">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>€37,000.00</h5>
                                            <p>Product Value</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>No Of Instalment Months</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Interest</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            {/* {merchantPersonalData &&
                                            <button
                                                className={`btn ${merchantPersonalData.userStatusName == "Active" ? "btn-success" : merchantPersonalData.userStatusName == "Suspended" ? 'btn-warning' : 'btn-danger'}`}
                                            >
                                                {merchantPersonalData.userStatusName}
                                            </button>
                                        } */}
                                            <p>First EMI Date</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Processing Fee</p>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                {/* -------------------------------------------------------------------------------------- */}
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Total payment</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Loan Amount</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Instalment Amount</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Interest Amount</p>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className='merchantNameDetails'>
                                            <h5>-</h5>
                                            <p>Bullet Loan Days</p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {/* -------------------------------------------------------------------------------------- */}
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>Na/Na</h5>
                                            <p>Down Payment Amount</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Interest Component</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Total Principal Paid</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Total Interest Paid</p>
                                        </div>
                                    </div><div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>No of Emi Paid</p>
                                        </div>
                                    </div><div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Current DPD</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5>Na/Na</h5>
                                            <p>Cooling Off Date</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Total Principal Pending</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Total Interest Pending</p>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>No of EMI Pending</p>
                                        </div>
                                    </div><div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Max DPD</p>
                                        </div>
                                    </div><div className="col-md-2">
                                        <div className='merchantNameDetails'>
                                            <h5></h5>
                                            <p>Loan Closing Date</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default LoanRepaymentDetails;

