import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import { FaFileCsv } from "react-icons/fa";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { formatDateTime } from "../component/common";
import { FaRegTimesCircle } from "react-icons/fa";
import TopShowMessage from "../component/TopShowMessage";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
  },
};

function ElectricianList() {
  const [merchantUserData, setMerchantUserData] = useState();
  console.log("merchantdata", merchantUserData);
  const [merchantActiveStatus, setMerchantActiveStatus] = useState();
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [showAleart, setshowAleart] = useState(false);
  const [showAleartRemark, setshowAleartRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [IsAccept, setIsAccept] = useState(false);
  const [IsReject, setIsReject] = useState(false);
  const [IsSuspended, setIsSuspended] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState();
  const [electricianUserId, setElectricianUserId] = useState();
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);

  const userRole = localStorage.getItem("USER_ROLE");

  const Accepthandle = () => {
    setIsReject(false);
    setIsAccept(true);
    setIsSuspended(false);
    setshowAleart(false);
    setshowAleartRemark(true);
  };
  const Rejecthandle = () => {
    setIsAccept(false);
    setIsReject(true);
    setIsSuspended(false);
    // setshowAleart(true);
    setshowAleartRemark(true);
  };

  const Suspendedhandel = () => {
    setIsAccept(false);
    setIsReject(false);
    setIsSuspended(true);
    // setshowAleart(true);
    setshowAleartRemark(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  let selectRef = null;
  const inputRef = useRef();

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantActiveStatus("");
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    merchantGetData();
    setSelectedOption(null);
    setSelectedOptionStatus(null);
  };

  // console.log(" merchantUserData ", merchantUserData);

  const statusName = { 1: "Active", 2: "In Active", 12: "Suspended" };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
      {
        id: 2,
        name: "Jane Smith",
        age: 30,
        city: "London",
        mobileNo: "9988776786",
      },
      {
        id: 3,
        name: "Bob Johnson",
        age: 35,
        city: "Paris",
        mobileNo: "9988776786",
      },
      // Add more data as needed
    ],
    []
  );
  const history = useHistory();

  async function merchantGetData() {
    setIsLoadingOn(true);
    const tempData = await getData("users/distributorUser/1?businessType=6", {});
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantUserData(tempData.data);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData.data.errors.msg || "Something went wrong",
      })
    }
  }

  useEffect(() => {
    merchantGetData();
  }, []);

  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType == "Electrician Id") {
      filterData = {
        distributorId: searchBoxValue,
      };
    } else if (
      merchantRefrenceType == "Electrician Id" &&
      merchantActiveStatus
    ) {
      filterData = {
        status: merchantActiveStatus,
        distributorName: searchBoxValue,
      };
    } else if (merchantRefrenceType == "Mobile No.") {
      filterData = {
        mobileNumber: searchBoxValue,
      };
    } else if (merchantRefrenceType == "Mobile No." && merchantActiveStatus) {
      filterData = {
        status: merchantActiveStatus,
        distributorName: searchBoxValue,
      };
    } else if (merchantRefrenceType == "Electrician Name") {
      filterData = {
        distributorName: searchBoxValue,
      };
    } else if (
      merchantRefrenceType == "Electrician Name" &&
      merchantActiveStatus
    ) {
      filterData = {
        status: merchantActiveStatus,
        distributorName: searchBoxValue,
      };
    } else if (merchantActiveStatus) {
      filterData = {
        status: merchantActiveStatus,
      };
    } else {
      // alert("Please select any one option");
    }

    setIsLoadingOn(true);

    // Constructing the query parameters for the URL
    const queryParams = new URLSearchParams(filterData).toString();

    console.log("queryParams---", queryParams);

    const tempData = await getData(`users/distributorUser/1?${queryParams}&businessType=6`);
    console.log("tempData---", tempData);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData.data.errors.msg || "Something went wrong",
      })
    }
  };

  const updateMerchantStatus = async () => {
    let filterData = {
      userId: electricianUserId,
      status: approvalStatus,
      remark: remark,
    };

    setIsLoadingOn(true);
    const tempData = await postData("users/updateUserStatus", filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setIsOpen(false);
      setShowTopMessage(true);
      let tempMerchantData = [];
      if (merchantUserData) {
        for (let i = 0; i < merchantUserData.length; i++) {
          const element = merchantUserData[i];
          // console.log(" element ", element);
          if (electricianUserId == element.id) {
            element.status = approvalStatus;
            element.statusName = statusName[`${approvalStatus}`];
          }
          tempMerchantData.push(element);
        }
      }
      setMerchantUserData(tempMerchantData);
      setTimeout(() => {
        setShowTopMessage(false);
      }, 3000);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData.data.errors.msg || "Something went wrong",
      })
    }
  };

  const displayDistributorInfo = (userId) => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.location = "/ElectricianDetails?userId=" + userId;
    } else {
      history.push({
        pathname: "/ElectricianDetails",
        state: { userId: userId },
      });
    }
  };

  const statusModalOpen = (userId) => {
    setElectricianUserId(userId);
    setIsOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1}.</span>
        ),
      },
      {
        Header: "Member Id",
        accessor: "id",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.id}
                  onClick={() => displayDistributorInfo(cell.row.original.id)}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.id}
                  </span>
                </button>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: false,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Mobile No.",
        accessor: "mobileNumber",
      },
      {
        Header: "Created date",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (
            <>
              {cell.row.original.createdAt &&
                formatDateTime(cell.row.original.createdAt)}
            </>
          );
        },
      },

      {
        Header: "Status",
        accessor: "statusName",
        Cell: (cell) => {
          const status = cell.row.original.statusName;
          return (
            <>
              <div className="text-center">
                <button
                  onClick={() => {
                    if (userRole === "Admin" || userRole === "Operation") {
                      statusModalOpen(cell.row.original.id)
                    }
                  }}
                  className={`btn btnMangStatus 
                ${status == "Active"
                      ? "btn-success"
                      : status == "Suspended"
                        ? "btn-warning"
                        : "btn-danger"
                    }`}
                  value={cell.row.original.statusName}
                >
                  {cell.row.original.statusName
                    ? cell.row.original.statusName
                    : "-"}
                </button>
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const StatusOption = [
    { value: "", label: "All" },
    { value: "1", label: "Active" },
    { value: "2", label: "Inactive" },
  ];
  const merchantOption = [
    { value: "Electrician Name", label: "Electrician Name" },
    { value: "Electrician Id", label: "Member Id" },
    { value: "Mobile No.", label: "Mobile No." },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      {showTopMessage ? (
        <TopShowMessage message="You Have Successfully Updated Details." />
      ) : (
        ""
      )}
      <h3 className="tableHeaderMsg">Electrician Table</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                ref={(ref) => {
                  selectRef = ref;
                }}
                placeholder="Select Status"
                name="role"
                options={StatusOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setSelectedOptionStatus(e);
                  setMerchantActiveStatus(e.value);
                }}
              />
            </div>

            <div className="col-md-3">
              <Select
                placeholder="Select Electrician"
                name="role"
                // ref={selectRef}
                options={merchantOption}
                value={selectedOption}
                onChange={(e) => {
                  setSelectedOption(e);
                  setMerchantRefrenceType(e.value);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                className="searchInputField"
                ref={inputRef}
                onChange={(e) => {
                  setSearchBoxValue(e.target.value);
                }}
                placeholder="Enter Details"
              />
            </div>
            <div className="col-md-2 text-center" onClick={searchManualData}>
              <button className="searchTableData">
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
          </div>
        </div>
      </div>

      {merchantUserData && <Table columns={columns} data={merchantUserData} />}

      {/* ------------- status View Modal ---------------------- */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Please Select Distributor Status</h4>
        <div className="modalUserStatus">
          <div className="confirmationbtn mb-8">
            <div className="wrapper_btnselt">
              <button
                onClick={Accepthandle}
                htmlFor="option-1"
                className={
                  IsAccept ? "acceptlisth option option-1" : "option option-1"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("1");
                  }}
                >
                  Active
                </span>
              </button>
              <button
                onClick={Rejecthandle}
                htmlFor="option-2"
                className={
                  IsReject ? "rejectlisth option option-2" : "option option-2"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("2");
                  }}
                >
                  Inactive
                </span>
              </button>
              <button
                onClick={Suspendedhandel}
                htmlFor="option-3"
                className={
                  IsSuspended
                    ? "suspendedMng option option-3"
                    : "option option-3"
                }
              >
                <span
                  onClick={() => {
                    setApprovalStatus("12");
                  }}
                >
                  Suspended
                </span>
              </button>
            </div>
          </div>

          <div className="remardi">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Remark</label>
              <textarea
                onChange={(event) => {
                  // console.log(" testing ", event.target.value);
                  setRemark(event.target.value);
                }}
                value={remark}
                className="form-control textacont"
                rows="3"
              ></textarea>
              {showAleart ? (
                <p className="requiredRemark">Remark is required...</p>
              ) : null}
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button onClick={updateMerchantStatus} className="btn btn-success">
              Submit
            </button>
          </div>
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}
    </div>
  );
}

export default ElectricianList;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                    <th
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                    </th>
                  </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* <tfoot>
                  {footerGroups.map(group => (
                    <tr {...group.getFooterGroupProps()}>
                      {group.headers.map(column => (
                        <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                      ))}
                    </tr>
                  ))}
                </tfoot> */}
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
