import React, { useState } from "react";
import { getData } from "../store/utils";
import { FaDownload } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import Loader from "../component/Loader";
import { format } from "date-fns";
// import { getData, postData } from '../store/utils';

const LoanTape = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [data, setData] = useState(null);
  const [isLoadingOn, setIsLoadingOn] = useState(false);

  const handleFromDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setFromDate(formattedDate);
  };

  const handleToDateChange = (date) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null;
    setToDate(formattedDate);
  };

  console.log("fromDate+++++",fromDate,toDate)

  const fetchData = async () => {
    try {
      // console.log("fromDate", fromDate);

      if (fromDate !== null || toDate !== null) {
        console.log("Testing Download file date ");
        setIsLoadingOn(true);
        const response = await getData(
          `creditLimit/getExcelCreditLimit?fromDate=${fromDate}&toDate=${toDate}`
        );
        setIsLoadingOn(false);
        if (response.statusCode == 200) {
          const file = window.open(response.data.Location);
          if (file) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "File Download successfully!",
            });
          } else {
            Swal.fire({
              icon: "Error",
              title: "Error!",
              text: "Error In File Download!",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: `${response.response.data.errors.msg}`,
          });
        }
      } else {
        setIsLoadingOn(true);
        const response = await getData(`creditLimit/getExcelCreditLimit`);
        setIsLoadingOn(false);
        if (response.statusCode == 200) {
          let file = window.open(response.data.Location);
          if (file) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "File Download successfully!",
            });
          } else {
            Swal.fire({
              icon: "Error",
              title: "Error!",
              text: "Error In File Download!",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            text: `${response.response.data.errors.msg}`,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDownload = () => {
    if (data) {
      const fileName = "loanTape_data.csv"; // Example file name
      const csv = convertDataToCSV(data); // Assuming convertDataToCSV is a function to convert data to CSV format
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
  };

  const convertDataToCSV = (data) => {
    // Example function to convert data to CSV format
    const header = Object.keys(data[0]).join(",");
    const csv = data.map((row) => Object.values(row).join(",")).join("\n");
    return `${header}\n${csv}`;
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Loan Tape</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-6">
              <div className="loanTapeChanges">
                <div style={{ marginRight: 10 }}>
                  <label>From Date</label>&nbsp;
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={fromDate ? new Date(fromDate) : null}
                    onChange={handleFromDateChange}
                    placeholderText="Select Start Date"
                    maxDate={new Date()}
                  />
                </div>
                <div>
                  <label>To Date</label>&nbsp;
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={toDate ? new Date(toDate) : null}
                    onChange={handleToDateChange}
                    placeholderText="Select End Date"
                    maxDate={new Date()}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <button className="searchTableData" onClick={fetchData}>
                <FaDownload /> Loan Tape
              </button>
            </div>
            <div className="col-md-2 text-center">
              <button
                className="resetTableData"
                onClick={() => {
                  setFromDate(null);
                  setToDate(null);
                }}
              >
                <HiRefresh /> Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {isLoadingOn ? <Loader /> : null} */}
      {/* Display your table or other components here */}
    </>
  );
};

export default LoanTape;
