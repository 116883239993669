import React, { useMemo, useState, useEffect } from "react";
import "../../css/makeTable.css";
import { getData } from "../../store/utils";
import Loader from "../../component/Loader";
import Swal from "sweetalert2";
import { formatDate } from "../../component/common.js";
// import myImg from "../../assets/img/equifax.png"

const MerchantCreditReport = () => {
  const [merchantReport, setMerchantReport] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [bureuReport, setBureuReport] = useState();
  const [gstScrubData, setGstScrubData] = useState();
  const [bureuReportMessage, setBureuReportMessage] = useState("");
  const [bureuParametersMessage, setBureuParametersMessage] = useState("");
  const [gstScrubMessage, setGstScrubMessage] = useState("");
  const [digiDataMessage, setDigiDataMessage] = useState("");
  const [derogData, setDerogData] = useState([]);
  const [equiFaxData, setEquiFaxData] = useState([]);
  const [creditLimitDataInfo, setCreditLimitDataInfo] = React.useState([]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const userId = urlSearchParams.get("userId");

  // ------------- Merchant Shop Address Data --------------------------

  async function getEquifaxData(){
    setIsLoadingOn(true);
    const tempData = await getData(
      `bureau/${userId}`
    );
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setEquiFaxData(tempData.data && tempData.data[0]);
    } 
    else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong",
      });
    }
  }

  async function merchantCreditReport() {
    setIsLoadingOn(true);
    const tempData = await getData(
      `digiData/getUserSmsFlag/nothing?userId=${userId}`
    );
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantReport(tempData.data && tempData.data[0]);
      if (tempData.data == null) {
        setDigiDataMessage(tempData?.message);
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong",
      });
    }
  }

  const checkOrderCreditLimit = async () => {
    if (userId) {
      setIsLoadingOn(true);
      let res = await getData(`users/get_credit_limit/${userId}`);
      setIsLoadingOn(false);
      if (res.statusCode == 200) {
        let tempValueData = [];
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          if (element) {
            tempValueData = element;
          }
        }
        setCreditLimitDataInfo(tempValueData);
      }
    }
  };

  useEffect(() => {
    checkOrderCreditLimit();
    getEquifaxData();
  }, []);

  const handleDerogData = async () => {
    setIsLoadingOn(true);
    try {
      const response = await getData(`digiData/getDerogData/${userId}`);
      if (response.statusCode === 200) {
        setDerogData(response.data);
        if (response.data == null) {
          setBureuReportMessage(response?.message);
        }
      } else {
        console.error(`Error: ${response.data.errors.msg}`);
      }
    } catch (error) {
      console.error(`API call failed: ${error}`);
    } finally {
      setIsLoadingOn(false);
    }
  };

  const handleRefreshDigiData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData(
      `digiData/smsProcessData/nothing?userId=${userId}`
    );
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      merchantCreditReport();
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors.msg || "Something went wrong",
      });
    }
  };

  // const handleRefreshDerogData = async () => {
  //   setIsLoadingOn(true);
  //   const tempData = await getData(`digiData/getALAE/${userId}`);
  //   setIsLoadingOn(false);
  //   if (tempData.statusCode == 200) {
  //     // alert();
  //     handleDerogData();
  //   }
  // };

  const handleBureaSummaryData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData(`digiData/getBureauSummaryData/${userId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setBureuReport(tempData.data && tempData.data[0]);
      if (tempData.data == null) {
        setBureuParametersMessage(tempData?.message);
      }
    }
  };

  const getGstScrubData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData(`digiData/getGSTScrub/params?userId=${userId}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setGstScrubData(tempData.data && tempData.data[0]);
      if (tempData.data == null) {
        setGstScrubMessage(tempData?.message);
      }
    }
  };

  useEffect(() => {
    merchantCreditReport();
    handleDerogData();
    handleBureaSummaryData();
    getGstScrubData();
  }, []);
  const validateNumber = (value) => {
    const numberValue = Number(value);
    return typeof numberValue === 'number' && !isNaN(numberValue) ? numberValue : 0;
  };

  const formatter = new Intl.NumberFormat("en-IN");
  const totalCreditLimit = validateNumber(creditLimitDataInfo?.creditLimit);
  const usedCreditLimit = validateNumber(creditLimitDataInfo?.usedCreditLimit);
  const availCreditLimit = validateNumber(creditLimitDataInfo?.availCreditLimit);

  console.log("creditLimitDataInfo+++++", creditLimitDataInfo)
  console.log("totalCreditLimit+++++", totalCreditLimit)
  console.log("usedCreditLimit+++++", usedCreditLimit)
  console.log("availCreditLimit+++++", availCreditLimit)

  const usedLimitPercentage = totalCreditLimit
    ? ((usedCreditLimit / totalCreditLimit) * 100).toFixed(2)
    : '0';

  const availLimitPercentage = totalCreditLimit
    ? ((availCreditLimit / totalCreditLimit) * 100).toFixed(2)
    : '0';

  console.log("usedLimitPercentage+++++", usedLimitPercentage)
  console.log("availLimitPercentage+++++", availLimitPercentage)

  // ------------- Merchant Shop Address Data --------------------------

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <div className="maincontainer">
        <div className="headingwithLogo">
          <img src="https://www.thesmbguide.com/images/equifax-1024x512-20191218.png"
            height={100}
            width={300}
            style={{ marginLeft: "30px", marginTop: "-35px" }}
          />
          <h2 className="creditTitle">Consumer Credit Report </h2>
        </div>
        <div class="container">
          <div class="left-side">
            <p>CLIENT ID:</p>
            <p>REPORT ORDER NO.:</p>
            <p>REFERENCE NUMEBR:</p>
          </div>
          <div class="right-side">
            <p>DATE:</p>
            <p>TIME:</p>
          </div>
        </div>
        <h6 className="consumerAddress"><strong>Consumer Name:</strong></h6>
        <div className="row personalInfo">
          <div className="col-md-4">Personal Information</div>
          <div className="col-md-4">Identification</div>
          <div className="col-md-4">Contact Details</div>
        </div>
        <div className="personInfoData">
          <div className="columnData">
            <p>Previous Name: {equiFaxData?.consumerName?.personalInformation?.previousName || "N/A"}</p>
            <p>Alias: {equiFaxData?.consumerName?.personalInformation?.aliasName || "N/A"}</p>
            <p>DOB: {formatDate(equiFaxData?.dob) || "N/A"}</p>
            <p>Age: {equiFaxData?.consumerName?.personalInformation?.age || "N/A"}</p>
            <p>Gender: {equiFaxData?.gender || "N/A"}</p>
            <p>Occupation: {equiFaxData?.occupation || "N/A"}</p>
          </div>
          <div className="columnData">
            <p>PAN: {equiFaxData?.pan || "N/A"}</p>
            <p>Voter ID: {equiFaxData?.voterId || "N/A"}</p>
            <p>Passport ID: {equiFaxData?.passportId || "N/A"}</p>
            <p>UID: {equiFaxData?.uid || "N/A"}</p>
            <p>Driving Licensce: {equiFaxData?.driversLicense || "N/A"}</p>
            <p>Ration Card: {equiFaxData?.rationCard || "N/A"}</p>
            <p>NAREGA Card No: {equiFaxData?.NREGACardNumber || "N/A"}</p>
            <p>CKYC: {equiFaxData?.cKYC || "N/A"}</p>
            <p>Photo Credit Card: {equiFaxData?.photoCreditCard || "N/A"}</p>
            <p>ID Other: {equiFaxData?.idOther || "N/A"}</p>
          </div>
          <div className="columnData">
            <p>Home: {equiFaxData?.home || "N/A"}</p>
            <p>Office: {equiFaxData?.office || "N/A"}</p>
            <p>Mobile: {equiFaxData?.mobile || "N/A"}</p>
            <p>Alt Home/Other No: {equiFaxData?.altHome || "N/A"}</p>
            <p>Alt Office No: {equiFaxData?.altOffice || "N/A"}</p>
            <p>Alt Mobile No: {equiFaxData?.altMobile || "N/A"}</p>
            <p>Email:{equiFaxData?.email || "N/A"}</p>
          </div>
        </div>
        <hr style={{ margin: "30px" }} />
        <h6 className="consumerAddress"><strong>Consumer Address:</strong></h6>
        <div className="row personalInfo">
          <table style={{ border: "1px" }} className="consumerAddTable">
            <th>Type</th>
            <th style={{ width: "60%" }}>Address</th>
            <th>State</th>
            <th>Postal</th>
            <th>Date Posted</th>
            <tbody style={{ backgroundColor: "transparent" }}>
              {equiFaxData?.consumerAddress?.map((row, index) => (
                <tr key={index}>
                  <td>{row.type || "N/A"}</td>
                  <td>{row.address || "N/A"}</td>
                  <td>{row.state || "N/A"}</td>
                  <td>{row.postal || "N/A"}</td>
                  <td>{row.DateReported || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr style={{ margin: "30px", border: "1px solid black" }} />
        <h6 className="consumerAddress"><strong>Equifax Score:</strong></h6>
          <div className="row" style={{backgroundColor:"transparent",margin:"25px",marginTop:"-15px",marginBottom:"15px"}}>
            <div className="col-md-3"><strong>Bereau Score Type: {equiFaxData?.bereauScoreType} </strong></div>
            <div className="col-md-3"><strong>Version: {equiFaxData?.version}</strong></div>
            <div className="col-md-3"><strong>Bereau Score Name: {equiFaxData?.bereauScoreName}</strong> </div>
            <div className="col-md-3"><strong>Value: {equiFaxData?.value}</strong></div>
          </div>
        <div className="row personalInfo">
          <table className="consumerAddTable">
              <th>SEQ</th>
              <th>Code</th>
              <th>Type</th>
              <th>Description</th>
              <th>Bureau Score ID</th>
              <tbody style={{ backgroundColor: "transparent" }}>
              {equiFaxData?.scoringElements?.map((row, index) => (
                  <tr key={index}>
                    <td className="recentActInqury">{row.seq}</td>
                    <td className="recentActInqury">{row.code}</td>
                    <td className="recentActInqury">{row.type}</td>
                    <td className="recentActInqury">{row.description}</td>
                    <td className="recentActInqury">{row.bureauScoreId}</td>
                  </tr>
              ))}
              </tbody>
            </table>
        </div>
        <hr style={{ margin: "30px", border: "1px solid black",marginTop:"1px" }} />
        <h6 className="consumerAddress"><strong>Recent Activity:</strong></h6>
        <div className="row personalInfo">
          <table className="consumerAddTable">
            <th style={{textAlign:"center", border:"none"}} colSpan={4}>
              Recent Activity(last 90 Days)
            </th>
            <tbody style={{ backgroundColor: "transparent" }}>
                <tr>
                  <td className="recentAct">Total Inquiries: {equiFaxData?.totalInquiries || "N/A"}</td>
                  <td className="recentAct">Account Opened: {equiFaxData?.accountsOpened || "N/A"}</td>
                  <td className="recentAct">Account Updated: {equiFaxData?.accountsUpdated || "N/A"}</td>
                  <td className="recentAct">Account Delinquent: {equiFaxData?.accountsDeliquent || "N/A"}</td>
                </tr>
            </tbody>
          </table>
        </div>
        <h6 className="consumerAddress" style={{marginTop:"30px"}}><strong>Summary:</strong></h6>
        <div className="row personalInfo">
          <table className="consumerAddTable">
            <th style={{textAlign:"center", border:"none"}} colSpan={3}>
              Credit Summary Report
            </th>
            <tbody style={{ backgroundColor: "transparent" }}>
                <tr>
                  <td className="recentAct">Number of Accounts : {equiFaxData?.summary?.numberOfAccounts || "N/A"}</td>
                  <td className="recentAct">Total Balance Amount : {equiFaxData?.totalBalanceAmount || "N/A"}</td>
                  <td className="recentAct">Recent Account : {equiFaxData?.recentAccount || "N/A"}</td>
                </tr>
                <tr>
                  <td className="recentAct">Number of Open Accounts : {equiFaxData?.summary?.numberOfOpenAccounts || "N/A"}</td>
                  <td className="recentAct">Total Past Due Amount : {equiFaxData?.summary?.totalPastDueAmount || "N/A"}</td>
                  <td className="recentAct">Oldest Account : {equiFaxData?.oldestAccount || "N/A"}</td>
                </tr>
                <tr>
                  <td className="recentAct">Number of Past Due Accounts: {equiFaxData?.summary?.numberOfPastDueAccounts || "N/A"}</td>
                  <td className="recentAct">Total High Credit: {equiFaxData?.totalHighCredit || "N/A"}</td>
                  <td className="recentAct">Total Credit Limit: {equiFaxData?.totalCreditLimit || "N/A"}</td>
                </tr>
                <tr>
                  <td className="recentAct">Number of Write-off Accounts: {equiFaxData?.summary?.numberOfWriteOffAccounts || "N/A"}</td>
                  <td className="recentAct">Total Sanction Amount: {equiFaxData?.totalSanctionAmount || "N/A"}</td>
                  <td className="recentAct">Single Highest Credit: {equiFaxData?.singleHighestCredit || "N/A"}</td>
                </tr>
                <tr>
                  <td className="recentAct">Number of Zero Balance Accounts {equiFaxData?.summary?.numberOfZeroBalanceAmounts || "N/A"}</td>
                  <td className="recentAct">Total Monthly Payment Amount : {equiFaxData?.totalMonthlyPaymentAmount || "N/A"}</td>
                  <td className="recentAct">Single Highest Sanction Amount : {equiFaxData?.singleHighestSanctionAmount || "N/A"}</td>
                </tr>
                <tr>
                  <td className="recentAct">Most Severe Status 24 Months : {equiFaxData?.summary?.mostSevereStatus || "N/A"}</td>
                  <td className="recentAct">Average Open Balance : {equiFaxData?.averageOpenBalance || "N/A"}</td>
                  <td className="recentAct">Single Highest Balance : {equiFaxData?.singleHighestBalance || "N/A"}</td>
                </tr>
            </tbody>
          </table>
        </div>
        <h6 className="consumerAddress" style={{marginTop:"30px"}}><strong>Account Details:</strong></h6>
        <div className="row personalInfo">
          <table className="consumerAddTable">
            <th style={{textAlign:"center", border:"none"}} colSpan={4}>
            Accounts
            </th>
            <tbody style={{ backgroundColor: "transparent" }}>
                <tr>
                  <td className="recentAct">Acct # </td>
                  <td className="recentAct"> Balance : </td>
                  <td className="recentAct">Open :</td>
                  <td className="recentAct">Date Reported :</td>
                </tr>
                <tr>
                  <td className="recentAct">Institution: </td>
                  <td className="recentAct">Past Due Amount : </td>
                  <td className="recentAct">Interest rate :</td>
                  <td className="recentAct">Date Opened :</td>
                </tr>
            </tbody>
          </table>
        </div>
        <hr style={{ margin: "30px", border: "1px solid black",marginTop:"35px" }} />
        <h6 className="consumerAddress" style={{marginTop:"30px"}}><strong>Enquiry Summary:</strong></h6>
        <div className="row personalInfo">
          <table className="consumerAddTable">
            <th>Purpose</th>
            <th>Total</th>
            <th>Past 30 Days</th>
            <th>Past 12 Months</th>
            <th>Past 24 Months</th>
            <th>Recent</th>
            <tbody style={{ backgroundColor: "transparent" }}>
            {equiFaxData?.enquirySummary?.map((row, index) => (
                <tr key={index}>
                  <td className="recentActInqury">{row.purpose}</td>
                  <td className="recentActInqury">{row.total}</td>
                  <td className="recentActInqury">{row.post30Days}</td>
                  <td className="recentActInqury">{row.post12Months}</td>
                  <td className="recentActInqury">{row.post24Months}</td>
                  <td className="recentActInqury">{row.recent}</td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
        <hr style={{ margin: "30px", border: "1px solid black",marginTop:"35px" }} />
        <h6 className="consumerAddress" style={{marginTop:"30px"}}><strong>Enquiries:</strong></h6>
        <div className="row personalInfo">
          <table className="consumerAddTable">
            <th>Institution</th>
            <th>Date</th>
            <th>Time</th>
            <th>Purpose</th>
            <th>Amount</th>
            <tbody style={{ backgroundColor: "transparent" }}>
              {equiFaxData?.enquiries?.map((row,index)=>(
                <tr key={index}>
                <td className="recentActInqury">{row.institution}</td>
                <td className="recentActInqury">{row.date}</td>
                <td className="recentActInqury">{row.time}</td>
                <td className="recentActInqury">{row.purpose}</td>
                <td className="recentActInqury">{row.amount}</td> 
              </tr>
              ))} 
            </tbody>
          </table>
        </div>
        {/* <hr style={{ margin: "30px", border: "1px solid black",marginTop:"35px" }} />
        <h6 className="consumerAddress" style={{marginTop:"30px"}}><strong>Input Enquiry:</strong></h6>
        <div className="row personalInfo">
          <div className="col-md-4">Personal & Account Information</div>
          <div className="col-md-4">ID & Phone Numbers</div>
          <div className="col-md-4">Contact Details</div>
        </div>
        <div className="personInfoData">
          <div className="columnData">
            <p>Previous Name:</p>
            <p>Alias:</p>
            <p>DOB:</p>
            <p>Age:</p>
            <p>Gender:</p>
            <p>Occupation:</p>
          </div>
          <div className="columnData">
            <p>PAN:</p>
            <p>Voter ID:</p>
            <p>Passport ID:</p>
            <p>UID:</p>
            <p>Driving Licensce:</p>
            <p>Ration Card:</p>
            <p>NAREGA Card No:</p>
            <p>CKYC:</p>
            <p>Photo Credit Card:</p>
            <p>ID Other:</p>
          </div>
          <div className="columnData">
            <p>Home:</p>
            <p>Office:</p>
            <p>Mobile:</p>
            <p>Alt Home/Other No:</p>
            <p>Alt Office No:</p>
            <p>Alt Mobile No:</p>
            <p>Email:</p>
          </div>
        </div> */}
      </div>

      <section className="creditReportMainSection">
        <div className="container-fluid">
          <div className="row assignLimitCustom">
            <div className="col-md-3">
              <p className="creditLimitHeading">
                Status:{" "}
                <span
                  className={`blueColorStyleSpan ${totalCreditLimit == null
                    ? "text-primary"
                    : totalCreditLimit > 0
                      ? "text-success"
                      : "text-danger"
                    }`}
                >
                  {totalCreditLimit == null
                    ? "No action taken"
                    : totalCreditLimit == 0
                      ? "Rejected"
                      : totalCreditLimit > 0
                        ? "Approved"
                        : "0"}
                </span>
              </p>
              <p className="creditLimitHeading">
                Remaining Limit:{" "}
                <span className="blueColorStyleSpan">
                  ₹ {availCreditLimit ? formatter.format(availCreditLimit) : 0}
                </span>
              </p>
            </div>
            <div className="col-md-3">
              <p className="creditLimitHeading">
                Assigned Limit:{" "}
                <span className="blueColorStyleSpan">
                  ₹ {totalCreditLimit ? formatter.format(totalCreditLimit) : 0}
                </span>
              </p>
              <p className="creditLimitHeading">
                Used Limit(%):{" "}
                <span className="orangeColorStyleSpan">
                  {usedLimitPercentage}%
                </span>
              </p>
            </div>
            <div className="col-md-3">
              <p className="creditLimitHeading">
                Used Limit:{" "}
                <span className="blueColorStyleSpan">
                  ₹ {usedCreditLimit ? formatter.format(usedCreditLimit) : 0}
                </span>
              </p>
              <p className="creditLimitHeading">
                Available Limit(%):{" "}
                <span className="orangeColorStyleSpan">
                  {availLimitPercentage}%
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                Derog Data
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-12">
                    {/* <button
                  onClick={handleRefreshDerogData} 
                   className="btn btn-success">Refresh Data</button> */}
                    {bureuReportMessage && (
                      <h5 className="mt-3">{bureuReportMessage}</h5>
                    )}
                  </div>
                  <div className="col-md-12 table-container">
                    {isLoadingOn ? (
                      <p>Loading...</p>
                    ) : (
                      derogData &&
                      derogData.map((row, index) => (
                        <div className="table-wrapper" key={index}>
                          <table className="data-table">
                            <tr>
                              <th>Lender name</th>
                              <td>{row.lender_name ? row.lender_name : "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Derog Status</th>
                              <td>
                                {row.derog_status ? row.derog_status : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>Derog Reason</th>
                              <td>
                                {row.derog_reason ? row.derog_reason : "N/A"}
                              </td>
                            </tr>
                          </table>
                          <br />
                        </div>
                      ))
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                Bureau Parameters
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-12">
                    {bureuParametersMessage ? (
                      <h5 className="mt-3">{bureuParametersMessage}</h5>
                    )
                      :
                      (
                        <div className="bankTableDetailsData">
                          {/* <table>
                      <tr>
                        <th>Bureau Score</th>
                        <td>{bureuReport?.bureauScore ? bureuReport?.bureauScore :"-"}</td>
                      </tr>

                      <tr>
                        <th>30 DPD (Yes/No)</th>
                        <td>{bureuReport?.monthSince30Dpd ? bureuReport?.monthSince30Dpd : '-'}</td>
                      </tr>

                      <tr>
                        <th>30 DPD Actual</th>
                        <td>{bureuReport?.["30_dpd_actual"]}</td>
                      </tr>

                      <tr>
                        <th>NPA in last 6 month</th>
                        <td>{bureuReport?.npa_last_6_month}</td>
                      </tr>

                      <tr>
                        <th>Default in last 2 years</th>
                        <td>{bureuReport?.defualt_last_2_yaers}</td>
                      </tr>
                      <tr>
                        <th>Credit enquiries in Last 60 days</th>
                        <td>{bureuReport?.credit_enquiries_last_60_days}</td>
                      </tr>

                      <tr>
                        <th>Secure loan</th>
                        <td>₹ {bureuReport?.secure_loan}</td>
                      </tr>
                      <tr>
                        <th>Maximum loan amount taken</th>
                        <td>₹ {bureuReport?.maximum_loan_amount_taken}</td>
                      </tr>
                      <tr>
                        <th>Loan taken from bank</th>
                        <td>{bureuReport?.loan_taken_from_bank}</td>
                      </tr>
                      <tr>
                        <th>Bureau veritas</th>
                        <td>{bureuReport?.bureau_veritas}</td>
                      </tr>
                    </table> */}

                          <table className="tableRowAdjustCustom">
                            <tr>
                              <th>Mobile Number</th>
                              <td>
                                {bureuReport?.mobileNumber
                                  ? bureuReport?.mobileNumber
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>live Loan Over Due Amount</th>
                              <td>
                                {bureuReport?.liveLoanOverDueAmount
                                  ? bureuReport?.liveLoanOverDueAmount
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Bureau Veritas</th>
                              <td>
                                {bureuReport?.bureauVeritas
                                  ? bureuReport?.bureauVeritas
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Maximum Loan Amount Taken</th>
                              <td>
                                {bureuReport?.maximumLoanAmountTaken
                                  ? bureuReport?.maximumLoanAmountTaken
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Bureau Score</th>
                              <td>
                                {bureuReport?.bureauScore
                                  ? bureuReport?.bureauScore
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>month Since 1 Dpd</th>
                              <td>
                                {bureuReport?.monthSince1Dpd
                                  ? bureuReport?.monthSince1Dpd
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>Month Since 30 Dpd</th>
                              <td>
                                {bureuReport?.monthSince30Dpd
                                  ? bureuReport?.monthSince30Dpd
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Month Since 60 Dpd</th>
                              <td>{bureuReport?.monthSince60Dpd ? bureuReport?.monthSince60Dpd
                                : "-"}</td>
                            </tr>
                            <tr>
                              <th>Month Since 90 Dpd</th>
                              <td>{bureuReport?.monthSince90Dpd ? bureuReport?.monthSince90Dpd : "-"}</td>
                            </tr>
                            <tr>
                              <th>Month Since 180 Dpd</th>
                              <td>{bureuReport?.monthSince180Dpd ? bureuReport?.monthSince180Dpd : "-"}</td>
                            </tr>

                            <tr>
                              <th>Count Of Written Off 2 Year</th>
                              <td>{bureuReport?.countOfWrittenOff2Y ? bureuReport?.countOfWrittenOff2Y : "-"}</td>
                            </tr>

                            <tr>
                              <th>Count Of Written Off 3 Year</th>
                              <td>{bureuReport?.countOfWrittenOff3Y ? bureuReport?.countOfWrittenOff3Y : "-"}</td>
                            </tr>
                            <tr>
                              <th>Is NTC</th>
                              <td>{bureuReport?.isNTC ? bureuReport?.isNTC : "-"}</td>
                            </tr>
                            <tr>
                              <th>Bounce Rate Percent</th>
                              <td>{bureuReport?.bounceRatePercent ? bureuReport?.bounceRatePercent : "-"}</td>
                            </tr>
                          </table>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree"
              >
                GST Scrub
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-12">
                    {gstScrubMessage ? (
                      <h5 className="mt-3">{gstScrubMessage}</h5>
                    )
                      :
                      (
                        <div className="bankTableDetailsData">
                          <table className="tableRowAdjustCustom">
                            <tr>
                              <th>GST No.</th>
                              <td>
                                {gstScrubData?.gstin ? gstScrubData?.gstin : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>GST revenue year</th>
                              <td>
                                {gstScrubData?.annualAggregateTurnoverYear
                                  ? gstScrubData?.annualAggregateTurnoverYear
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>GST revenue slab</th>
                              <td>
                                {gstScrubData?.annualAggregateTurnover
                                  ? gstScrubData?.annualAggregateTurnover
                                  : "-"}
                              </td>
                            </tr>

                            {/* <tr>
                        <th>Gst induced income</th>
                        <td>{gstScrubData?.gstin}</td>
                      </tr> */}

                            <tr>
                              <th>GST Aadhaar Verified</th>
                              <td>
                                {gstScrubData?.aadhaarVerified == "True"
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <th>Aadhaar Verification Date</th>
                              <td>
                                {gstScrubData?.aadhaarVerificationDate
                                  ? gstScrubData.aadhaarVerificationDate
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>constitute of business</th>
                              <td>
                                {gstScrubData?.constitutionOfBusiness
                                  ? gstScrubData?.constitutionOfBusiness
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>GST Status</th>
                              <td>
                                <p
                                  className={
                                    gstScrubData?.status == "Active"
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {gstScrubData?.status ? gstScrubData?.status : "-"}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <th>Tax payer type</th>
                              <td>
                                {gstScrubData?.taxpayerType
                                  ? gstScrubData?.taxpayerType
                                  : "_"}
                              </td>
                            </tr>
                            <tr>
                              <th>GST Address</th>
                              <td>
                                {gstScrubData?.principalAddress
                                  ? gstScrubData?.principalAddress
                                  : "-"}
                              </td>
                            </tr>
                            {/* <tr>
                        <th>GST Name</th>
                        <td>{gstScrubData?.legalName ? gstScrubData?.legalName  : "-"}</td>
                      </tr> */}
                            <tr>
                              <th>GST Mobile Number</th>
                              <td>
                                {gstScrubData?.principalAddressMobile
                                  ? gstScrubData?.principalAddressMobile
                                  : "-"}
                              </td>
                            </tr>
                            {/* <tr>
                        <th>GST additional address</th>
                        <td>
                        {gstScrubData?.legalName ? gstScrubData?.legalName  : "-"}
                        </td>
                      </tr> */}

                            <tr>
                              <th>GST legal Name</th>
                              <td>
                                {gstScrubData?.legalName
                                  ? gstScrubData?.legalName
                                  : "-"}
                              </td>
                            </tr>

                            <tr>
                              <th>GST trade name</th>
                              <td>
                                {gstScrubData?.tradeName
                                  ? gstScrubData?.tradeName
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>State Jurisdiction</th>
                              <td>
                                {gstScrubData?.stateJurisdiction
                                  ? gstScrubData?.stateJurisdiction
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Nature of Business</th>
                              <td>
                                {gstScrubData?.natureOfBusiness
                                  ? gstScrubData?.natureOfBusiness
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>GST Registration Date</th>
                              <td>
                                {gstScrubData?.dateOfRegistration
                                  ? gstScrubData?.dateOfRegistration
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>PAN Number</th>
                              <td>{gstScrubData?.pan ? gstScrubData?.pan : "-"}</td>
                            </tr>
                            <tr>
                              <th>Annual Gst TurnOver</th>
                              <td>
                                {gstScrubData?.annualGstTurnOver
                                  ? gstScrubData?.annualGstTurnOver
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Monthly Gst TurnOver</th>
                              <td>
                                {gstScrubData?.monthlyGstTurnOver
                                  ? gstScrubData?.monthlyGstTurnOver
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Gross Total Income</th>
                              <td>
                                {gstScrubData?.grossTotalIncome
                                  ? gstScrubData?.grossTotalIncome
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Financial Year For GTI</th>
                              <td>
                                {gstScrubData?.fyForGrossTotalIncome
                                  ? gstScrubData?.fyForGrossTotalIncome
                                  : "-"}
                              </td>
                            </tr>
                          </table>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFour"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseFour"
              >
                Digi Data
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseFour"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                <div className="row">
                  {digiDataMessage ? (
                    <>
                      <div className="col-md-6">
                        <h5 className="mt-3">{digiDataMessage}</h5>
                      </div>
                      <div className="col-md-6 text-end mb-2">
                        <button
                          onClick={handleRefreshDigiData}
                          className="btn btn-success"
                        >
                          Refresh Data
                        </button>
                      </div>
                    </>
                  )
                    :
                    (
                      <>
                        <div className="col-md-12 text-end mb-2">
                          <button
                            onClick={handleRefreshDigiData}
                            className="btn btn-success"
                          >
                            Refresh Data
                          </button>
                        </div>
                        <div className="col-md-12">
                          <div className="bankTableDetailsData">
                            <h4 style={{ color: "#6E12F9", fontSize: 17 }}>
                              Last fetched date:{" "}
                              {merchantReport ? merchantReport.updated_at : "-"}
                            </h4>
                            <table className="tableRowAdjustCustom">
                              <tr>
                                <th>Number of Overdue Messages</th>
                                <td>
                                  {merchantReport ? merchantReport.Count_Overdue : "-"}
                                </td>
                              </tr>

                              <tr>
                                <th>Total Amount in Overdue</th>
                                <td>
                                  ₹{" "}
                                  {merchantReport
                                    ? merchantReport.Total_Amount_Overdue
                                    : "0"}
                                </td>
                              </tr>

                              <tr>
                                <th>Highest Amount in Overdue</th>
                                <td>
                                  ₹{" "}
                                  {merchantReport
                                    ? merchantReport.Max_Amount_Overdue
                                    : "0"}
                                </td>
                              </tr>

                              <tr>
                                <th>Entities Present in Overdue Messages</th>
                                <td>-</td>
                              </tr>

                              <tr>
                                <th>Number of Cheque Bounce Messages</th>
                                <td>
                                  {merchantReport
                                    ? merchantReport.Count_ChqBounce
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Total Amount in Cheque Bounces</th>
                                <td>
                                  ₹{" "}
                                  {merchantReport
                                    ? merchantReport.Total_Amount_ChqBounce
                                    : "0"}
                                </td>
                              </tr>

                              <tr>
                                <th>Highest Cheque Bounce Amount</th>
                                <td>
                                  ₹{" "}
                                  {merchantReport
                                    ? merchantReport.Max_Amount_ChqBounce
                                    : "0"}
                                </td>
                              </tr>
                              <tr>
                                <th>Entities Present in Cheque Bounce Messages</th>
                                <td>-</td>
                              </tr>
                              <tr>
                                <th>Number of Successful Cheque Deposits</th>
                                <td>
                                  {merchantReport
                                    ? merchantReport.Count_Successful_ChqDeposit
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Cheque Bounce Rate</th>
                                <td>
                                  {merchantReport
                                    ? merchantReport.Bounce_Rate &&
                                    merchantReport.Bounce_Rate.toFixed(2)
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Total Credit Amount</th>
                                <td>-</td>
                              </tr>
                              <tr>
                                <th>Number of Credit Transactions</th>
                                <td>-</td>
                              </tr>
                              <tr>
                                <th>Total Debit Amount</th>
                                <td>-</td>
                              </tr>
                              <tr>
                                <th>Number of Debit Transactions</th>
                                <td>-</td>
                              </tr>
                              <tr>
                                <th>Monthly Cash Flow</th>
                                <td>-</td>
                              </tr>
                              <tr>
                                <th>Annual Cash flow</th>
                                <td>-</td>
                              </tr>
                              <tr>
                                <th>Use of Gambling apps</th>
                                <td>
                                  {merchantReport
                                    ? merchantReport.Red_Flag_Apps_Used
                                    : "-"}
                                </td>
                              </tr>

                              <tr>
                                <th>Use of which banks</th>
                                <td>
                                  {merchantReport ? merchantReport.Banks_UPI_Used : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Use of UPI apps</th>
                                <td>
                                  {merchantReport ? merchantReport.Banks_UPI_Used : "-"}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFive"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseFive"
              >
                Ledger/ sales
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseFive"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="bankTableDetailsData">
                      <table className="tableRowAdjustCustom">
                        <tr>
                          <th>Total Sales last 12 months</th>
                          <td>-</td>
                        </tr>

                        <tr>
                          <th>Total Sales ever</th>
                          <td>-</td>
                        </tr>

                        <tr>
                          <th>Total Invoices Paid</th>
                          <td>-</td>
                        </tr>

                        <tr>
                          <th>Vintage with Distributor</th>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Address as per Ledger</th>
                          <td>-</td>
                        </tr>

                        <tr>
                          <th>Total Debit Entities</th>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th>Total Credit Entities</th>
                          <td>-</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSix"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseSix"
              >
                Field Data
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseSix"
              className="accordion-collapse collapse"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="bankTableDetailsData">
                      <table className="tableRowAdjustCustom">
                        <tr>
                          <th>Visit (Yes/No)</th>
                          <td>Yes</td>
                        </tr>

                        <tr>
                          <th>Shop/Business Name</th>
                          <td>-</td>
                        </tr>

                        <tr>
                          <th>Establishment Year</th>
                          <td>-</td>
                        </tr>

                        <tr>
                          <th>Online order Application</th>
                          <td>Yes</td>
                        </tr>

                        <tr>
                          <th>Shop Type</th>
                          <td>Permanent</td>
                        </tr>
                        <tr>
                          <th>Shop Property</th>
                          <td>Owned</td>
                        </tr>
                        <tr>
                          <th>Average monthly purchase</th>
                          <td>1 lac - 3 lac</td>
                        </tr>
                        <tr>
                          <th>Average monthly sales</th>
                          <td>1 lac - 2 lac</td>
                        </tr>
                        <tr>
                          <th>Distributor Credit</th>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <th>Shop Size</th>
                          <td>200-800 sq ft</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MerchantCreditReport;
