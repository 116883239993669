
import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import TopShowMessage from "../component/TopShowMessage";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
  },
};

function ProgramList() {
  const [merchantUserData, setMerchantUserData] = useState();
  const [selectedOptionOneStatus, setSelectedOptionOneStatus] = useState(null);
  const [selectedOptionTwoStatus, setSelectedOptionTwoStatus] = useState(null);
  const [selectedOptionThreeStatus, setSelectedOptionThreeStatus] = useState(null);
  const [selectedOptionFourthStatus, setSelectedOptionFourthStatus] = useState(null);
  const [distributorUserId, setDistributorUserId] = useState();
  const [brandId, setBrandId] = useState();
  const [programId, setProgramId] = useState();
  const [categoryId, setCategoryId] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [loanStatusData, setLoanStatusData] = useState();
  const [distributorUserData, setDistributorUserData] = useState([]);
  const [distributorUserBrands, setDistributorUserBrands] = useState([]);
  const [programListDrop, setProgramListDrop] = useState([]);
  const [categoryListDrop, setCategoryListDrop] = useState([]);

  const userRole = localStorage.getItem("USER_ROLE");

  const inputRef = useRef();

  const resetFilters = () => {
    setDistributorUserId("");
    setBrandId("");
    setProgramId("");
    setCategoryId("");
    updateOrderStatus();
    setSelectedOptionOneStatus(null);
    setSelectedOptionTwoStatus(null);
    setSelectedOptionThreeStatus(null);
    setSelectedOptionFourthStatus(null);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
    ],
    []
  );

  const searchManualData = async () => {
    let filterData = {};
    if (distributorUserId && brandId && programId && categoryId) {
      filterData = {
        distributorId: distributorUserId,
        brandId: brandId,
        programId: programId,
        productCategoryId: categoryId,
      };
    } else if (distributorUserId && brandId) {
      filterData = {
        distributorId: distributorUserId,
        brandId: brandId,
      };
    } else if (distributorUserId && programId) {
      filterData = {
        distributorId: distributorUserId,
        programId: programId,
      };
    } else if (distributorUserId && programId && categoryId) {
      filterData = {
        distributorId: distributorUserId,
        programId: programId,
        productCategoryId: categoryId,
      };
    } else if (brandId && programId) {
      filterData = {
        brandId: brandId,
        programId: programId,
      };
    } else if (brandId && programId && categoryId) {
      filterData = {
        brandId: brandId,
        programId: programId,
        productCategoryId: categoryId,
      };
    } else if (distributorUserId) {
      filterData = {
        distributorId: distributorUserId,
      };
    } else if (brandId) {
      filterData = {
        brandId: brandId,
      };
    } else if (programId) {
      filterData = {
        programId: programId,
      };
    } else if (categoryId) {
      filterData = {
        productCategoryId: categoryId,
      };
    } else {
      alert("Please select any one option");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(`users/getProgram/1?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  };

  const history = useHistory();

  const updateOrderStatus = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("users/getProgram/1");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantUserData(tempData.data);
    }
  };

  useEffect(() => {
    updateOrderStatus();
  }, []);

  const statusModalOpen = (item) => {
    setLoanStatusData(item);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const columns = useMemo(
    
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1}.</span>
        ),
      },
      {
        Header: 'Program Code',
        accessor: 'programCode',

      },
      {
        Header: 'Program Name',
        accessor: 'programName',

      },
      {
        Header: 'Distributor Name',
        accessor: "distributorName",
      },
      {
        Header: 'Distributor Id',
        accessor: 'distributorId'
      },
      {
        Header: 'Brand Name',
        accessor: 'brandName',

      },
      {
        Header: 'Brand ID',
        accessor: 'brandId',

      },
      {
        Header: 'Product Category Name ',
        accessor: 'productCategoryName ',

      }
      , {
        Header: 'Product Category ID',
        accessor: 'productCategoryId',

      },
      {
        Header: 'Cash Discount',
        accessor: 'cashDiscount',

      },
      {
        Header: 'Action',
        accessor: '',
        Cell: ({ cell }) => {
          

          return (
            
            <>
              <div
                className="loanIdDownloadBtnDiv d-flex justify-content-between align-items-center text-center"
                style={{ padding: "5px !important;" }}
              >
                <button
                  className={`btn-sm btn-success`}
                  onClick={() => {
                    statusModalOpen(cell.row.original);
                  }}
                >
                  <span style={{ fontWeight: "500" }}>View</span>
                </button>
                
                
                {/* { userRole === "Admin" || userRole === "Operation" && ( */}
                  <button
                    className="btn-sm btn-warning"
                    onClick={() => {
                      if (userRole === "Admin" || userRole === "Operation") {
                        const { id } = cell.row.original;
                        window.open(
                          `/EditProgram?id=${id}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    <span>Edit</span>
                  </button>
                {/* )} */}

              </div>
            </>
          );
        },

      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  async function DistributorGetData() {
    setIsLoadingOn(true);
    const tempData = await getData("users/distributorUser/1", {});
    setIsLoadingOn(false);
    const tempDistData = [];
    if (tempData.statusCode == 200) {
      for (let i = 0; i < tempData.data.length; i++) {
        const element = tempData.data[i];
        tempDistData.push({
          value: element.id,
          label: `${element.name}-${element.id}`,
        });
      }
      setDistributorUserData(tempDistData);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  }

  async function DistributorGetBrands() {
    setIsLoadingOn(true);
    const tempData = await getData("users/getBrands/1");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200 && tempData.data) {
      const tempDistData = tempData.data.map((element) => ({
        value: element.id,
        label: element.name,
      }));
      setDistributorUserBrands(tempDistData);
    } else {
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  }

  async function getProgramListData() {
    setIsLoadingOn(true);
    const tempData = await getData("users/getProgramList/nothing");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200 && tempData.data) {
      const tempDistData = tempData.data.map((element) => ({
        value: element.programId,
        label: element.programName,
      }));
      setProgramListDrop(tempDistData);
    } else {
      console.error("Error fetching distributor Program list.");
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  }

  async function getProductCategoryListData() {
    setIsLoadingOn(true);
    const tempData = await getData("users/getProductCategory/nothing");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200 && tempData.data) {
      const tempDistData = tempData.data.map((element) => ({
        value: element.id,
        label: element.name,
      }));
      setCategoryListDrop(tempDistData);
    } else {
      console.error("Error fetching distributor Program list.");
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.errors.msg || "Something went wrong!!!"
      })
    }
  }
  useEffect(() => {
    DistributorGetBrands();
    DistributorGetData();
    getProgramListData();
    getProductCategoryListData();
  }, []);

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Program List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <label style={{ marginBottom: 4, fontWeight: "500" }}>
                Select Distributor
              </label>
              <Select
                placeholder="Select"
                name="role"
                options={distributorUserData}
                value={selectedOptionOneStatus}
                onChange={(e) => {
                  setDistributorUserId(e.value);
                  setSelectedOptionOneStatus(e);
                }}
              />
            </div>

            <div className="col-md-2">
              <label style={{ marginBottom: 4, fontWeight: "500" }}>
                Select Brand
              </label>
              <Select
                placeholder="Select Brand"
                name="role"
                options={distributorUserBrands}
                value={selectedOptionTwoStatus}
                onChange={(e) => {
                  setBrandId(e.value);
                  setSelectedOptionTwoStatus(e);
                }}
              />
            </div>

            <div className="col-md-2">
              <label style={{ marginBottom: 4, fontWeight: "500" }}>
                Select Program
              </label>
              <Select
                placeholder="Select Program"
                name="role"
                options={programListDrop}
                value={selectedOptionThreeStatus}
                onChange={(e) => {
                  setProgramId(e.value);
                  setSelectedOptionThreeStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <label style={{ marginBottom: 4, fontWeight: "500" }}>
                Select Category
              </label>
              <Select
                placeholder="Select Category"
                name="role"
                options={categoryListDrop}
                value={selectedOptionFourthStatus}
                onChange={(e) => {
                  setCategoryId(e.value);
                  setSelectedOptionFourthStatus(e);
                }}
              />
            </div>
            <div className="col-md-4 buttonAlignmentProgram">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
              <button onClick={resetFilters} className="resetTableData">
                <HiRefresh /> Reset
              </button>
              {userRole === "Admin" || userRole === "Operation" && (
                <button
                  className="btn btnAddCredit"
                  onClick={() => {
                    history.push("/ProgramAddData");
                  }}
                >
                  Add
                </button>
              )}

            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {merchantUserData && (
          <Table columns={columns} data={merchantUserData} />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Program List Status</h4>
        <div className="modalUserPaymentStatus">
          {loanStatusData && (
            <table className="programList">
              <tbody>
                <tr>
                  <th>Program Name:</th>
                  <td>{loanStatusData.name}</td>
                </tr>
                <tr>
                  <th>Program Code:</th>
                  <td>{loanStatusData.code}</td>
                </tr>
                <tr>
                  <th>Product Type:</th>
                  <td>{loanStatusData.productType}</td>
                </tr>
                <tr>
                  <th>Product Code:</th>
                  <td>{loanStatusData.productCode}</td>
                </tr>
                <tr>
                  <th>Brand:</th>
                  <td>{loanStatusData.anchor}</td>
                </tr>
                <tr>
                  <th>Cash Discount:</th>
                  <td>{loanStatusData.cashDiscount}</td>
                </tr>
                <tr>
                  <th>Segment:</th>
                  <td>{loanStatusData.segment}</td>
                </tr>
                <tr>
                  <th>Eligibility:</th>
                  <td>{loanStatusData.eligibility}</td>
                </tr>
                <tr>
                  <th>Total Program Limit:</th>
                  <td>{loanStatusData.totalProgramLimit}</td>
                </tr>
                <tr>
                  <th>Approved Date:</th>
                  <td>{loanStatusData.approvedDate}</td>
                </tr>
                <tr>
                  <th>Limit Expiry Date:</th>
                  <td>{loanStatusData.limitExpiryDate}</td>
                </tr>
                <tr>
                  <th>Max Limit Per Account:</th>
                  <td>{loanStatusData.maxLimitPerAccount}</td>
                </tr>
                <tr>
                  <th>Request Auto Finance:</th>
                  <td>{loanStatusData.requestAutoFinance}</td>
                </tr>
                <tr>
                  <th>Stale Invoice Period:</th>
                  <td>{loanStatusData.staleInvoicePeriod}</td>
                </tr>
                <tr>
                  <th>Stop Supply:</th>
                  <td>{loanStatusData.stopSupply}</td>
                </tr>
                <tr>
                  <th>FLDG:</th>
                  <td>{loanStatusData.FLDG}</td>
                </tr>
                <tr>
                  <th>Default Payment Terms:</th>
                  <td>{loanStatusData.defaultPaymentTerms}</td>
                </tr>
                <tr>
                  <th>Invoice Attachment Mandatory:</th>
                  <td>{loanStatusData.invoiceAttachmentMandatory}</td>
                </tr>
                <tr>
                  <th>Partner:</th>
                  <td>{loanStatusData.partner}</td>
                </tr>
                <tr>
                  <th>Consolidate UTR:</th>
                  <td>{loanStatusData.consolidateUTR}</td>
                </tr>
                <tr>
                  <th>Recourse:</th>
                  <td>{loanStatusData.recourse}</td>
                </tr>
                <tr>
                  <th>Company Board Resolution Attachment:</th>
                  <td>{loanStatusData.companyBoardResolutionAttachment}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </Modal>

      {/* ------------- status View Modal ---------------------- */}
    </div>
  );
}

export default ProgramList;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10},
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* <tr>
                    <th
                      colSpan={visibleColumns.length}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                    </th>
                  </tr> */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot className="orderTableFooter">
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
