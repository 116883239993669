export const formatDateTime = (date) => {
    let tempTime = date && date.split("T");
    // console.log(" date ", tempTime[1].split(".")[0]);
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    // let tempDate = new Date(date)
      // console.log(" d ====== ", date.split(" "));
      // console.log(" d ====== ", d);
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
  
    return [day, month, year].join("-")+" "+tempTime[1].split(".")[0];
  };

  export const formatDate = date => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [day, month, year].join('-');
  };